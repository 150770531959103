import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import { IPayLoadGetList } from 'app/common/interface'
import { IClinic } from 'app/model/unit.model'
import R from 'app/assets/R'
import { ResponseType } from 'parkway-web-common'

interface IPatient {
  _id: string
  name: string
  contact: string
  id: string
  link: string
}

interface ITreatment {
  name: string
}

export interface ICampaign {
  _id: string
  name: string
  priority: number
}

interface IVoucher {
  discountType: EDiscountType
  amount: number
  code?: string
}

enum EDiscountType {
  Percent = 'percent',
  Amount = 'amount',
}

export interface IParamsGetReport18 extends IPayLoadGetList {
  startDate?: string
  endDate?: string
  keyword?: string
  clinicIds?: string[]
  campaignIds?: string[]
  year?: string
  quarter?: string
  month?: string
  week?: string
}

export interface IReport018 {
  date?: string
  clinic?: IClinic
  patient?: IPatient
  treatmentId?: string
  treatment?: ITreatment
  used?: number
  retailPrice?: number
  discountedTotal?: number
  voucherId?: string
  voucher?: IVoucher
  campaign?: ICampaign
}

export const DefineDiscountTypeEnum = {
  [DiscountTypeEnum.Percent]: {
    keyI18n: R.strings.percent,
    symbol: "%"
  },
  [DiscountTypeEnum.Amount]: {
    keyI18n: R.strings.cash,
    symbol: "đ"
  },
}
export type ResponseReport18Type = ResponseType<IReport018[]>
