import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 0px ${PADDING.md} ${PADDING.md} ${PADDING.md};
`

export const HeadPageWrapper = styled(HeaderPage)`
  padding-bottom: 0px !important;
`

export const BaseTableReport = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    text-align: left !important;
    font-size: ${FONT_SIZE.xxs} !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: rgba(0, 34, 68, 0.7) !important;
  }

  .ant-table-thead > tr {
    .discount-value-column {
      text-align: center !important;
    }

    .retail-price-column {
      text-align: center !important;
    }

    .priority-column {
      text-align: right !important;
    }
  }

  .date-time-column {
    min-width: 160px !important;
  }

  .phone-column {
    min-width: 135px !important;
  }

  .patient-code {
    min-width: 135px !important;
  }

  .patient-name {
    min-width: 220px !important;
  }

  .discount-total {
    min-width: 87px !important;
  }

  .service-column {
    min-width: 400px !important;
  }

  .discount-value-column {
    min-width: 116px !important;
  }

  .discount-type-column {
    min-width: 90px !important;
  }

  .retail-price-column {
    min-width: 160px !important;
  }

  .voucher-code-column {
    min-width: 160px !important;
  }

  .campaign-name-column {
    min-width: 427px !important;
  }

  .priority-column {
    min-width: 76px !important;
  }
`
