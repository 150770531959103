export default {
  yes: 'Có',
  no: 'Không',
  fill_barcode: 'Nhập mã',
  see_more: 'Xem thêm',
  choose: 'Chọn',
  find: 'Tìm kiếm',
  address: 'Địa chỉ',
  language: 'Ngôn ngữ',
  vietnamese: 'Tiếng việt',
  english: 'English',
  foreign_language: 'Ngoại ngữ',
  home: 'Trang chủ',
  phone: 'Số điện thoại',
  email: 'Email',
  send: 'Gửi',
  next: 'Tiếp theo',
  back: 'Quay lại',
  minute: 'phút',
  hour: 'giờ',
  send_code: 'Gửi mã',
  login: 'Đăng nhập',
  user_name: 'Tên đăng nhập',
  password: 'Mật khẩu',
  re_password: 'Nhập lại mật khẩu',
  re_password_not_match: 'Mật khẩu không trùng khớp',
  change_password: 'Đổi mật khẩu',
  change_password_success: 'Đổi mật khẩu thành công',
  login_success: 'Đăng nhập thành công',
  forgot_password: 'Quên mật khẩu',
  require_field: 'Vui lòng nhập "{{field}}", đây là trường bắt buộc',
  require_import_field: 'Vui lòng import "{{field}}", đây là trường bắt buộc',
  require_select_field: 'Vui lòng chọn "{{field}}", đây là trường bắt buộc',
  required: 'Bắt buộc',
  minimum_field: 'Vui lòng nhập tối thiểu {{count}} kí tự',
  maximum_field: 'Vui lòng nhập tối đa {{count}} kí tự',
  register: 'Đăng kí',
  confirm_password: 'Xác nhận mật khẩu',
  verify_by_phone: 'Xác thực bằng số điện thoại',
  verify_by_email: 'Xác thực bằng số email',
  code_verify: 'Mã xác thực',
  message_verify_phone:
    'Bạn vui lòng kiểm tra trong tin nhắn Zalo của số điện thoại {{phone}} để lấy mã xác thực.',
  message_verify_email:
    'Bạn vui lòng kiểm tra trong hộp thư (bao gồm cả thư rác) của email "{{email}}" để lấy mã xác thực.',
  re_send_verify_code: 'Gửi lại mã xác thực',
  resend: 'Gửi lại',
  time_resend_verify_code: 'Gửi lại sau {{timer}} giây',
  verify: 'Xác thực',
  logout: 'Đăng xuất',
  description: 'Mô tả',
  full_name: 'Họ và tên',
  regex_name: 'Tên cần đúng định dạng',
  regex_user_name: 'User name cần đúng định dạng (Không bao gồm dấu "cách")',
  using_by: 'Được sử dụng bởi',
  require_email: 'Vui lòng nhập email',
  require_contact_name: 'Vui lòng nhập tên người liên hệ',
  regex_email: 'Vui lòng nhập đúng định dạng email',
  regex_phone: 'Vui lòng nhập đúng định dạng số điện thoại',
  send_contact_success:
    'Đã gửi thông tin liên hệ thành công, cảm ơn quý khách.',
  please_enter_correct_format: 'Vui lòng  định dạng',
  medical_examination: 'Khám bệnh',
  hr: 'Nhân sự',
  finance: 'Tài chính',
  inventory_management: 'Quản lý kho',
  purchasing_management: 'Quản lý mua hàng',
  customer_care: 'Chăm sóc khách hàng',
  clinic_management: 'Quản lý phòng khám',
  management: 'Quản lý',
  doctor: 'Bác sĩ',
  company: 'Công ty',
  organizational_chart: 'Sơ đồ tổ chức',
  announcement: 'Thông báo',
  internal_news: 'Tin nội bộ',
  onboarding: 'OnBoard',
  report: 'Báo cáo',
  marketing: 'Marketing',
  accounting: 'Kế toán',
  work_schedule: 'Lịch làm việc',
  attendance_record: 'Chấm công',
  mailbox: 'Hộp thư',
  my_requests: 'Yêu cầu của tôi',
  my_tasks: 'Công việc của tôi',
  my_income: 'Thu nhập của tôi',
  dashboard: 'Dashboard',
  profile: 'Thông tin cá nhân',
  read_all: 'Đánh dấu đọc tất cả',
  view_all: 'Xem tất cả',
  remember_me: 'Ghi nhớ',
  parkway_dental: 'Nha khoa Parkway',
  description_parkway_auth:
    'Nha khoa Parkway là hệ thống nha khoa toàn diện và chuyên nghiệp, đặc biệt về chỉnh nha và implant, đáp ứng trọn vẹn nhu cầu nha khoa của khách hàng.',
  development: 'Đang phát triển',
  customers: 'Khách hàng',
  region: 'Khu vực',
  revenue: 'Doanh thu',
  total_revenue: 'Tổng doanh thu',
  pending_revenue: 'Doanh thu ước đoán',
  actual_revenue: 'Thực thu',
  compared_to_last_month: 'So với tháng trước',
  today_revenue: 'Doanh thu hôm nay',
  branch_name: 'Tên chi nhánh',
  overall_today_summary: 'Tổng quan hôm nay',
  overview: 'Tổng quan',
  area: 'Khu vực',
  clinic: 'Phòng khám',
  department: 'Phòng ban',
  ordinal_number: 'STT',
  notification: 'Thông báo',
  not_done: 'Chưa làm',
  done: 'Hoàn thành',
  in_progress: 'Đang làm',
  see_detail: 'Xem chi tiết',
  mail: 'Thư',
  new_mail: 'Thư mới',
  important: 'Quan trọng',
  show_less: 'Đọc tiếp',
  collapse: 'Thu gọn',
  last_time_update: 'Cập nhật gần nhất',
  service: 'Dịch vụ',
  report_001: 'Report 001 - Đo lường chuyển đổi từ số lượng Lead sang Check in',
  report_002: 'Report 002 - Đo lường hiệu quả tư vấn của CSKH mới (Team Call)',
  report_003: 'Report 003 - Đo lường hiệu quả tư vấn của CSKH cũ (Team Call)',
  report_004:
    'Report 004 - Đo lường hiệu quả chốt của khu vực/phòng khám/bác sĩ',
  report_004_doctor:
    'Report 004 - Đo lường hiệu quả chốt của phòng khám theo bác sĩ',
  report_004_area:
    'Report 004 - Đo lường hiệu quả chốt của phòng khám theo khu vực',
  report_005:
    'Report 005 - Báo cáo doanh thu từng chi nhánh theo ngày-tuần-tháng',
  report_006:
    'Report 006 - Báo cáo doanh thu từng chi nhánh theo nhóm sản phẩm',
  report_007: 'Report 007 - Báo cáo doanh thu theo nguồn khách hàng',
  report_008: 'Report 008 - Top 10 sản phẩm cả hệ thống',
  report_009: 'Report 009 - Báo cáo nhóm người dùng theo sản phẩm',
  report_010: 'Report 010 - Báo cáo nhóm người dùng theo khu vực',
  report_011: 'Report 011 - Top người dùng chi tiêu nhiều',
  report_012: 'Report 012 - P&L',

  report_001_description:
    'Báo cáo đo lường chuyển đổi từ số lượng Lead sang Check in',
  report_002_description:
    'Báo cáo đo lường hiệu quả tư vấn của CSKH mới (Team Call)',
  report_003_description:
    'Báo cáo đo lường hiệu quả tư vấn của CSKH cũ (Team Call)',
  report_004_description:
    'Báo cáo đo lường hiệu quả chốt của khu vực/phòng khám/bác sĩ',
  report_004_area_description:
    'Báo cáo đo lường hiệu quả chốt của phòng khám theo khu vực',
  report_004_doctor_description:
    'Báo cáo đo lường hiệu quả chốt của phòng khám theo bác sĩ',
  report_005_description:
    'Báo cáo doanh thu từng chi nhánh theo ngày-tuần-tháng',
  report_006_description: 'Báo cáo doanh thu từng chi nhánh theo nhóm sản phẩm',
  report_007_description: 'Báo cáo doanh thu theo nguồn khách hàng',
  report_008_description: 'Báo cáo Top 10 sản phẩm cả hệ thống',
  report_009_description: 'Báo cáo nhóm người dùng theo sản phẩm',
  report_010_description: 'Báo cáo nhóm người dùng theo khu vực',
  report_011_description: 'Báo cáo Top người dùng chi tiêu nhiều',
  report_012_description: 'Báo cáo P&L',

  budget: 'Ngân sách',
  spent_actual: 'Thực chi',
  percent_budget_using: '% Đã chi',
  planing: 'Kế hoạch',
  actuals: 'Thực đạt',
  cpql: 'CPQL',
  price: 'Giá',
  percent_complete_target_api: '% Đạt KPI',
  number_checkin: 'Số check in',
  percent_achievement: '% Đạt',
  number_q_lead: 'Số Qlead',
  see_with: 'Xem theo',
  week: 'Tuần',
  choose_date: 'Chọn ngày',
  choose_week: 'Chọn tuần',
  document: 'Tài liệu tập tin',
  name_employee: 'Tên nhân viên',
  number_date_re_cared: 'Số data chăm sóc lại',
  number_off_appointments_scheduled: 'Số đặt lịch hẹn',
  number_check_in_achieved: 'Số check in thực đạt',
  crv_1: 'CVR1',
  crv_1_period: 'CRV1 cùng kì',
  crv_2: 'CVR2',
  crv_2_period: 'CRV2 cùng kì',
  crv_1_growth_in_period: 'Tỉ lệ tăng trưởng CVR1 so với cùng kỳ',
  crv_2_growth_in_period: 'Tỉ lệ tăng trưởng CVR2 so với cùng kỳ',
  number_of_cases_closed: 'Số ca chốt',
  crv3: 'CVR3 hiện tại',
  crv3_with_period: 'CVR3 cùng kỳ',
  percent_crv3_growth_in_period: 'Tỉ lệ tăng trưởng CVR3 so với cùng kỳ',
  number_of_check_ins: 'Số lượt check in',
  week1: 'Tuần 1',
  week2: 'Tuần 2',
  week3: 'Tuần 3',
  week4: 'Tuần 4',
  week_no: 'Tuần {{week}}',
  total_revenue_month: 'Tổng doanh thu tháng {{month}}',
  total_revenue_target: 'Tổng doanh thu kế hoạch',
  percent_of_target_complete: '% Đạt được',
  month1: 'Tháng 1',
  month2: 'Tháng 2',
  month3: 'Tháng 3',
  month4: 'Tháng 4',
  month5: 'Tháng 5',
  month6: 'Tháng 6',
  month7: 'Tháng 7',
  month8: 'Tháng 8',
  month9: 'Tháng 9',
  month10: 'Tháng 10',
  month11: 'Tháng 11',
  month12: 'Tháng 12',
  month_no: 'Tháng {{month}}',
  total_revenue_year: 'Tổng doanh thu năm {{year}}',
  group_product: 'Nhóm sản phẩm',
  a1_implant: 'A1 - Implant',
  a2_implant: 'A2 - Implant',
  a3_implant: 'A3 - Implant',
  total_revenue_actual: 'Tổng doanh thu thực tế',
  total_revenue_projected: 'Tổng doanh thu ước đoán',
  total_revenue_planned: 'Tổng doanh thu kế hoạch',
  total_revenue_achieved: 'Tổng doanh thu thực hiện được',
  group_customer: 'Nhóm khách hàng',
  revenue_week: 'Doanh thu tuần {{week}}',
  hcm_area: 'Khu vực Hồ Chí Minh',
  hanoi_area: 'Khu vực Hà Nội',
  digital_ad: 'Quảng cáo digital',
  percentage_of_total_revenue: '% Trên tổng doanh thu',
  system_wide: 'Cả hệ thống',
  product: 'Sản phẩm',
  quarter_in_year: 'Quý trong năm',
  gender: 'Giới tính',
  age: 'Độ tuổi',
  cases_in_product_group: 'Số ca thuộc nhóm sản phẩm',
  fixed_braces: 'Chỉnh nha cố định',
  other_products: 'Sản phẩm khác',
  revenue_of_product_group: 'Doanh thu nhóm sản phẩm',
  cases_in_area: 'Số ca thuộc khu vực',
  revenue_of_area: 'Doanh thu thuộc khu vực',
  province: 'Tỉnh',
  telesales_cared: 'Telesales chăm sóc',
  other_customer: 'Khách hàng khác',
  old_customer: 'Khách hàng cũ',
  import_budget_week: 'Import ngân sách theo tuần',
  import_budget_month: 'Import ngân sách theo tháng',
  import_qlead_actual_week: 'Import Số QLead Actuals theo tuần',
  import_qlead_actual_month: 'Import Số QLead Actuals theo tháng',
  import_cpql_numbers_week: 'Import số CPQL theo tuần',
  import_cpql_numbers_month: 'Import số CPQL theo tháng',
  import_weekly_appointment_schedule: 'Import Số lịch hẹn planning theo tuần',
  appointment_schedule: 'Số lịch hẹn kế hoạch',
  check_in_schedule: 'Số kế hoạch check in',
  import_monthly_appointment_schedule: 'Import Số lịch hẹn planning theo tháng',
  import_cost_of_goods_sold_and_selling_expenses_weekly:
    'Giá vốn hàng bán và chi phí bán hàng theo tuần',
  cost_of_goods_sold_and_selling_expenses:
    'Giá vốn hàng bán và chi phí bán hàng',
  marketing_plan: 'Kế hoạch Marketing',
  pricing_management: 'Quản lý bảng giá',
  pricing_group: 'Nhóm bảng giá',
  area_apply: 'Khu vực áp dụng',
  default: 'Mặc định',
  import_cost_of_goods_sold_and_selling_expenses_monthly:
    'Import giá vốn hàng bán và chi phí bán hàng theo tháng',
  import_weekly_revenue: 'Kế hoạch doanh thu theo tuần',
  import_monthly_revenue: 'Kế hoạch doanh thu theo tháng',
  import: 'Import',
  from_date: 'Từ ngày',
  to_date: 'Đến ngày',
  start_date: 'Ngày bắt đầu',
  end_date: 'Ngày kết thúc',
  number_planned: 'Số Plan',
  number_actual: 'Số Actual',
  export_excel: 'Xuất Excel',
  download_example_import_file: 'Tải mẫu file import',
  upload: 'Tải lên',
  cp_nvl_live: 'CP NVL Trực tiếp',
  doctor_department: 'Bộ phận bác sĩ',
  nursing_department: 'Bộ phận y tá',
  advisory_department: 'Bộ phận tư vấn',
  x_ray_technical_department: 'Bộ phận X-ray technical',
  reception_department: 'Bộ phận lễ tân',
  security_and_housekeeping_department: 'Bộ phận tạp vụ bảo vệ',
  initial_construction: 'Xây dựng ban đầu',
  machinery_and_equipment: 'Máy móc thiết bị',
  rental_expenses: 'Chi phí tiền thuê',
  location_rental_tax_expenses: 'Chi phí thuế thuê địa điểm',
  other_operating_expenses: 'Các chi phí vận hành khác',
  card_swipe_installment_fees: 'Phí quẹt thẻ - trả góp',
  others_cosmetics: 'Khác (thẩm mỹ)',
  plan_number: 'Số plan',
  performance: 'Performance',
  branch: 'Chi nhánh',
  call_center: 'Call center',
  ccdc: 'CCDC',
  location_rental_expenses: 'Chi phí thuê địa điểm',
  depreciation_expenses: 'Chi phí khấu hao TSCĐ/CCDC',
  salary_expenses_allowances: 'Chi phí lương, phụ cấp...',
  cost_of_goods_sold: 'Giá vốn hàng bán',
  selling_expenses: 'Chi phí bán hàng',
  download_successful: 'Download thành công',
  import_file_successful: 'Import file thành công',
  import_file_error: 'Import file lỗi',
  month: 'Tháng',
  import_file: 'Import file',
  created: 'Mới',
  requested: 'Đã giao',
  recall: 'Gọi lại',
  confirm_customer: 'Xác nhận khách hàng',
  confirmed: 'Đã xác nhận',
  cancel: 'Hủy',
  orthodontic: 'Mắc cài',
  general_treatment: 'Điều trị tổng quát',
  treatments: 'Dịch vụ',
  orthodontic_correction: 'Chỉnh nha',
  invisalign: 'Invisalign',
  implant: 'Implant',
  number_of_cases_in_the_region: 'Số ca thuộc khu vực',
  revenue_in_the_region: 'Doanh số thuộc khu vực',
  hochiminh: 'Hồ Chí Minh',
  hanoi: 'Hà Nội',
  annual_expenditure_amount: 'Số tiền chi tiêu trong năm',
  number_of_purchases: 'Số lần mua hàng',
  average_order_value: 'Giá trị trung bình đơn hàng',
  groupA1: 'Nhóm A1',
  groupA2: 'Nhóm A2',
  groupA3: 'Nhóm A3',
  groupA4: 'Nhóm A4',
  number_of_service_uses: 'Số lần sử dụng dịch vụ',
  number_new_lead: 'Số lead mới',
  compared_to_last_day: 'So với hôm qua',
  year: 'Năm',
  from_then_util_now: 'Từ trước đến nay',
  quarter: 'Quý',
  quarter_num: 'Quý {{quarter}}',
  male: 'Nam',
  female: 'Nữ',
  other: 'Khác',
  all_system: 'Toàn hệ thống',
  porcelain_cosmetic_dentistry: 'Răng sứ thẩm mỹ',
  customer_care_plan: 'Kế hoạch chăm sóc khách hàng',
  number_customer_re_care: 'Số khách hàng chăm sóc lại',
  number_qlead_actual: 'Số QLead Actuals',
  account_management: 'Quản lý tài khoản',
  employee_id: 'Mã nhân viên',
  account_name: 'Tên tài khoản',
  status: 'Trạng thái',
  active: 'Đang hoạt động',
  deactive: 'Ngừng hoạt động',
  active_en: 'Active',
  deleted: 'Đã xóa',
  deleted_en: 'Deleted',
  error: 'Error',
  error_en: 'Error',
  search: 'Tìm kiếm',
  all: 'Tất cả',
  delete: 'Xoá',
  done_button: 'Đã xong',
  update: 'Cập nhật',
  edit: 'Sửa',
  id_property: 'Mẫu mã',
  barcode: 'Barcode',
  add_account: 'Thêm tài khoản',
  first_name: 'Họ',
  last_name: 'Tên',
  enter_field: 'Nhập {{field}}',
  please_select_status: 'Vui lòng chọn trạng thái',
  inactive: 'Không hoạt động',
  inactive_en: 'Inactive',

  update_account: 'Cập nhật tài khoản',
  action: 'Hành động',
  update_account_id_success: 'Cập nhật tài khoản {{account}} thành công',
  delete_account: 'Xoá tài khoản',
  are_you_want_delete_account: `Bạn xác nhận xoá tài khoản`,
  profile_title: 'Profile',
  please_select_profile: 'Chọn profile',
  once_the_account_is_locked_it_cannot_be_recovered:
    'Tài khoản sau khi khoá sẽ không thể khôi phục lại',
  please_select_role: 'Vui lòng chọn cấp bậc',
  job_title: 'Quản lý chức danh',
  update_time: 'Thời gian cập nhật',
  days_of_week: {
    monday: 'T2',
    tuesday: 'T3',
    wednesday: 'T4',
    thursday: 'T5',
    friday: 'T6',
    saturday: 'T7',
    sunday: 'CN',
  },
  job_title_content: {
    management: 'Quản lý chức danh',
    description: 'Mô tả',
    name: 'Tên',
    group: 'Nhóm chức danh',
    add_job_title: 'Thêm chức danh',
    job_title_management: 'Quản lý trực tiếp',
    hasTimekeeping: 'Có chấm công',
    dayOffType: 'Loại ngày nghỉ',
    dayOfDynamic: 'Linh hoạt',
    dayOfFixed: 'Cố định',
    id: 'ID Chức danh',
    name_create: 'Tên chức danh',
    please_select_job_title_management: 'Chọn quản lý',
    are_you_want_delete_job_title: 'Bạn xác nhận xoá chức danh',
    once_the_job_title_is_locked_it_cannot_be_recovered:
      'Chức danh sau khi khoá sẽ không thể khôi phục lại',
    please_select_dayOffDynamic: 'Số ngày nghỉ',
    please_select_job_title_group: 'Chọn nhóm chức danh',
    please_select_dayOffFixed: 'Số ngày nghỉ cố định',
  },
  job_title_field: {
    job_title_string_id: 'code',
    name: 'name',
    description: 'description',
    status: 'status',
    role: 'role',
    job_title_management: 'jobTitleManagement',
  },
  job_title_level: 'Quản lý cấp bậc',
  job_title_level_content: {
    title: 'Cấp bậc',
    management: 'Quản lý cấp bậc',
    description: 'Mô tả',
    name: 'Tên',
    add: 'Thêm cấp bậc',
    id: 'ID Cấp bậc',
    name_create: 'Tên cấp bậc',
    are_you_want_delete: 'Bạn xác nhận xoá cấp bậc',
    once_the_job_title_level_is_locked_it_cannot_be_recovered:
      'Cấp bậc sau khi khoá sẽ không thể khôi phục lại',
  },
  job_title_level_field: {
    job_title_level_string_id: 'code',
    name: 'name',
    description: 'description',
    status: 'status',
    job_title_level_id: 'jobTitleLevelId',
  },
  unit: 'Quản lý phòng ban',
  select_unit: 'Chọn phòng ban',
  unit_content: {
    title: 'phòng ban',
    create_unit_child: 'Tạo phòng ban con',
    unit_parent: 'Phòng ban trực thuộc',
    management: 'Quản lý phòng ban',
    unit_department: 'Khối',
    job_title_management: 'Quản lý trực tiếp',
    unitTypeId: 'Loại phòng ban',
    description: 'Mô tả',
    name: 'Tên',
    add: 'Thêm phòng ban',
    _id: 'ID',
    id: 'Mã Phòng ban',
    please_select_unit_department: 'Chọn khối',
    please_select_unitTypeId: 'Chọn loại phòng ban',
    please_select_unit_job_title_management: 'Chọn quản lý',
    please_select_unit_job_title_vice: 'Chọn phó quản lý',
    name_create: 'Tên phòng ban',
    are_you_want_delete: 'Bạn xác nhận xoá phòng ban',
    once_the_unit_is_locked_it_cannot_be_recovered:
      'Phòng ban sau khi khoá sẽ không thể khôi phục lại',
  },
  unit_field: {
    name: 'Tên phòng ban',
    description: 'Mô tả',
    status: 'Trạng thái',
    unit_string_id: 'Mã',
    unit_department_string_id: 'Khối',
    unitTypeId: 'Loại',
    job_title_management: 'Chức danh quản lý',
    job_title_vice: 'Chức danh phó quản lý',
  },
  account: 'Tài khoản',
  create_profile: 'Thêm nhân viên',
  update_profile: 'Cập nhật nhân viên',
  profile_content: {
    title: 'nhân viên',
    management: 'Quản lý nhân viên',
    profile_department: 'Khối',
    job_title: 'Chức danh',
    description: 'Mô tả',
    firstname: 'Tên',
    lastname: 'Họ',
    email: 'Email',
    phone: 'Số điện thoại',
    address: 'Địa chỉ',
    add: 'Thêm nhân viên',
    name: 'Tên',
    id: 'ID nhân viên',
    mobile: 'Số điện thoại',
    employeeid: 'Mã nhân viên',
    employee_old_id: 'Mã nhân viên cũ',
    job_title_main_column: 'Chức danh chính',
    job_title_secondary_column: 'Chức danh kiêm nhiệm',
    job_title_main: '1. Chức danh chính',
    job_title_secondary: '2. Chức danh kiêm nhiệm',
    job_title_secondary_child: 'Chức danh kiêm nhiệm',
    please_select_department: 'Chọn phòng ban',
    please_select_job_title: 'Chọn chức danh',
    please_select_account: 'Chọn tài khoản',
    name_create: 'Tên nhân viên',
    info_section: 'Thông tin nhân viên',
    job_title_section: 'Chức danh nhân viên',
    are_you_want_delete: 'Bạn xác nhận xoá nhân viên',
    create_profile_success: 'Thêm nhân viên thành công',
    once_the_profile_is_locked_it_cannot_be_recovered:
      'Nhân viên sau khi khoá sẽ không thể khôi phục lại',
    add_work_info_main: 'Thêm chức danh chính',
    edit_work_info_main: 'Chỉnh sửa chức danh chính',
    add_work_info_aka: 'Thêm chức danh kiêm nhiệm',
    option_create: 'Tạo tài khoản',
    option_select_id: 'Đã có tài khoản',
    option_non: 'Không tạo tài khoản',
    skill: 'Kỹ năng nhân viên',
    add_skill: 'Thêm kỹ năng',
    please_select_skill: 'Chọn kỹ năng',
    please_fill_skill: 'Nhập kỹ năng',
    industry: 'Loại',
    add_industry: 'Thêm loại',
    please_select_industry: 'Chọn loại',
    please_fill_industry: 'Nhập loại',
    level: 'Cấp độ',
    add_level: 'Thêm cấp độ',
    please_select_level: 'Chọn cấp độ',
    please_fill_level: 'Nhập cấp độ',
    degree: 'Bằng cấp nhân viên',
    add_degree: 'Thêm bằng cấp',
    edit_degree: 'Chỉnh sửa bằng cấp',
    please_select_degree: 'Chọn bằng cấp',
    degree_classification: 'Xếp loại bằng cấp',
    add_degree_classification: 'Thêm xếp loại bằng cấp',
    please_select_degree_classification: 'Chọn xếp loại',
    area: 'Khu vực',
    clinic: 'Chi nhánh',
    department: 'Bộ phận',
  },
  profile_field: {
    name: 'name',
    email: 'email',
    mobile: 'mobile',
    status: 'status',
    account: 'accountid',
    last_name: 'lastname',
    first_name: 'firstname',
    employeeid: 'employeeid',
    employee_old_id: 'employee_old_id',
    description: 'description',
    profileTypeId: 'profileTypeId',
    profile_string_id: 'profileStringId',
    profile_department_string_id: 'profileDepartmentStringId',
    skill: 'skill',
    level: 'level',
    industry: 'industry',
    classification: 'classification',
    workinfo_unitid: 'workinfo_unitid',
    workinfo_job_title: 'workinfo_jobtitleid',
    workinfo_start_date: 'workinfo_startdate',
    workinfo_end_date: 'workinfo_enddate',
    workinfo_status: 'workinfo_status',
  },
  projected_revenue: 'Doanh thu ước đoán',
  survey_report: 'Báo cáo đánh giá chất lượng dịch vụ',
  survey_report_content: {
    star: 'sao',
    by_day: 'Đánh giá chất lượng dịch vụ trung bình theo ngày',
    review_type_need_to_impove: 'Dịch vụ cần cải thiện',
    next_promotor_score: 'Đánh giá điểm NPS (Net Promotor Score)',
    chart_by_day_label_header: '/ Trung bình các phòng khám tại khu vực',
    chart_by_day_label_footer: 'Tổng lượt đánh giá',
    excellent: 'Xuất sắc',
    good: 'Tốt',
    middle: 'Tạm ổn',
    bad: 'Kém',
    select_area: 'Tất cả khu vực',
    select_clinic: 'Tất cả phòng khám',
    review: 'Đánh giá',
    unReview: 'Không đánh giá',
    nps_average: 'Đánh giá trung bình',
    nps_choosen: 'Khu vực đã chọn',
    date: 'Ngày',
    start_no: 'Số sao',
  },
  marketing_bp: 'BP Marketing',
  operation_bp: 'BP vận hành',
  training_bp: 'BP đào tạo',
  hr_bp: 'BP nhân sự',
  accounting_bp: 'BP kế toán',
  purchasing_bp: 'BP mua hàng',
  other_expenses: 'Chi phí khác',
  other_income: 'Thu nhập khác',
  interest_on_deposits: 'Lãi tiền gửi',
  other_costs: 'Chi phí khác',
  business_management_expenses: 'Chi phí quản lý doanh nghiệp',
  other_profits: 'Lợi nhuận khác',
  download_excel_success: 'Tải file excel thành công',
  skill: 'Quản lý kỹ năng',
  skill_content: {
    title: 'Kỹ năng',
    management: 'Quản lý kỹ năng',
    description: 'Mô tả',
    industry: 'Loại',
    levelSkill: 'Cấp độ',
    rangeExperience: 'Năm kinh nghiệm',
    name: 'Tên',
    add: 'Thêm kỹ năng',
    name_create: 'Tên kỹ năng',
    are_you_want_delete: 'Bạn xác nhận xoá kỹ năng',
    exists_skill: 'Kỹ năng đã tồn tại',
    once_the_skill_is_locked_it_cannot_be_recovered:
      'Kỹ năng sau khi xoá sẽ không thể khôi phục lại',
    select_industry: 'Chọn loại',
    select_level_skill: 'Chọn vị trí',
    select_range_experience: 'Chọn số năm',
  },
  skill_field: {
    name: 'name',
    industry: 'Loại',
    levelSkill: 'Cấp độ',
    rangeExperience: 'Năm kinh nghiệm',
    status: 'status',
  },
  degree: 'Quản lý bằng cấp',
  degree_content: {
    title: 'Bằng cấp',
    management: 'Quản lý bằng cấp',
    see_degree: 'Xem bằng cấp',
    description: 'Mô tả',
    industry: 'Loại',
    start: 'Ngày cấp',
    end: 'Ngày hết hạn',
    has_end: 'Có thời hạn',
    no_end: 'Vô thời hạn',
    name: 'Tên',
    add: 'Thêm bằng cấp',
    name_create: 'Tên bằng cấp',
    placehoder_upload_document:
      'Tải lên file của bạn ( Định dạng file pdf,docx. Kích thước tối đa 20 MB )',
    placeholder_upload_document:
      'Tải lên file của bạn ( Định dạng file pdf,docx. Kích thước tối đa 20 MB )',
    placeholder_upload_image:
      'Tải lên ảnh của bạn ( Định dạng file png, jpg. Kích thước tối đa 5 MB )',
    are_you_want_delete: 'Bạn xác nhận xoá bằng cấp',
    once_the_degree_is_locked_it_cannot_be_recovered:
      'Bằng cấp sau khi xoá sẽ không thể khôi phục lại',
    select_industry: 'Chọn loại',
  },
  degree_field: {
    name: 'Tên',
    degree: 'Bằng cấp',
    industry: 'Loại',
    status: 'Trạng thái',
    degree_classification: 'Xếp loại',
  },
  degree_classification: {
    excellent: 'Giỏi',
    good: 'Khá',
    average_good: 'Trung bình khá',
    average: 'Trung bình',
    below_average: 'Dưới trung bình',
    weak: 'Yếu',
    unknown: 'Không rõ',
  },
  check_in_planing: 'Check in kế hoạch',
  report_group: {
    customer_care: 'Nhóm chăm sóc khách hàng',
    marketing: 'Nhóm marketing',
    operation: 'Nhóm vận hành',
    accounting: 'Nhóm kế toán',
    import_data: 'Nhập dữ liệu',
    debt_management: 'Quản lý công nợ',
    report: 'Báo cáo',
    service_product: 'Sản phẩm dịch vụ',
  },
  check_in_customer_plan: 'Kế hoạch số khách hàng check in',
  manage_customer_debt_contract: 'Quản lý công nợ khách hàng theo hợp đồng',
  manage_customer_debt: 'Quản lý công nợ khách hàng',
  manage_customer_debt_remain: 'Còn lại',
  manage_customer_patient_code: 'Mã khách hàng',
  manage_customer_patient_name: 'Tên',
  manage_customer_debt_payment_total: 'Tổng phải thanh toán',
  manage_customer_debt_deposit_total: 'Tổng cọc',
  manage_customer_debt_refund_total: 'Tổng hoàn tiền',
  manage_customer_debt_paid_total: 'Tổng đã thanh toán',
  manage_customer_debt_remain_total: 'Còn lại',
  manage_customer_payment_type: 'Loại thanh toán',
  manage_customer_clinic_name: 'Phòng khám',
  manage_customer_debt_treatment: 'Tên gói',
  manage_customer_debt_treatment_total: 'Số tiền của gói',
  manage_customer_debt_treatment_date: 'Ngày phát sinh gói',
  manage_customer_debt_tip_end_name: 'Tên thủ thuật kết thúc',
  manage_customer_debt_tip_end_date: 'Ngày kết thúc',
  manage_customer_choose_paid_type: 'Chọn loại thanh toán',
  manage_customer_debt_choose_treatment: 'Chọn gói',
  manage_customer_debt_choose_tip: 'Chọn Thủ thuật',
  manage_customer_debt_tip_start_date: 'Ngày bắt đầu',
  manage_customer_tip_start_date: 'Ngày bắt đầu',
  daily_invoice: 'Báo cáo khoản thu',
  deposit: 'Cọc',
  installment: 'Trả góp',
  one_time_payment: 'Thanh toán 1 lần',
  no_paid: 'Chưa phát sinh',
  booking: 'Xử lý yêu cầu đặt lịch hẹn của quản lý Call Center',
  booking_employee: 'Xử lý yêu cầu đặt lịch hẹn của nhân viên Call Center',
  booking_content: {
    title: 'Call center',
    management: 'Xử lý yêu cầu đặt lịch hẹn của quản lý Call Center',
    description: 'Mô tả',
    name: 'Tên',
    phone: 'Số điện thoại',
    treatments: 'Dịch vụ',
    area: 'Khu vực',
    clinic: 'Phòng khám',
    doctor: 'Bác sĩ',
    date: 'Ngày khám',
    time: 'Giờ khám',
    note: 'Ghi chú',
    callCenter: 'Nhân viên call center',
    calling: 'Bạn đang gọi cho {{name}}',
    add: 'Tạo lịch hẹn',
    name_create: 'Tên bệnh nhân',
    are_you_want_delete: 'Bạn xác nhận xoá lịch hẹn',
    exists_booking: 'Call center đã tồn tại',
    once_the_booking_is_locked_it_cannot_be_recovered:
      'Call center sau khi xoá sẽ không thể khôi phục lại',
    profile_call_center: 'Nhân viên call center',
    select_profile_call_center: 'Chọn nhân viên',
  },
  booking_field: {
    name: 'name',
    phone: 'Số điện thoại',
    treatments: 'Dịch vụ',
    clinic: 'Phòng khám',
    doctor: 'Bác sĩ',
    callCenter: 'Nhân viên call center',
    status: 'status',
    note: 'Ghi chú',
    area: 'Khu vực',
    date: 'Ngày khám',
    time: 'Giờ khám',
  },
  process_appointment_requests: 'Xử lý yêu cầu đặt lịch hẹn',
  create_appointment: 'Tạo lịch hẹn',
  BS: 'Bs.',
  day: 'Ngày',
  appointment_note: 'Chú thích',
  confirm: 'Xác nhận',
  guest_cancels_appointment: 'Khách hủy đặt lịch',
  confirm_guest_cancels_appointment: 'Xác nhận huỷ thông tin đặt lịch ',
  confirm_describe_guest_cancels_appointment:
    ' . Thông tin đặt lịch sẽ bị huỷ. (Hủy có thể khôi phục lại)',
  schedule_booking: 'Đặt lịch hẹn',
  patient_name: 'Tên bệnh nhân',
  patient_name_required: 'Vui lòng nhập tên bệnh nhân',
  patient_name_placeholder: 'Nhập họ tên',

  phone_label: 'Số điện thoại',
  phone_label_required: 'Vui lòng nhập số điện thoại',
  phone_label_placeholder: 'Nhập số điện thoại',

  area_label: 'Khu vực',
  area_label_required: 'Vui lòng chọn khu vực',
  area_label_placeholder: 'Chọn khu vực',

  clinic_label: 'Phòng khám',
  clinic_label_required: 'Vui lòng chọn phòng khám',
  clinic_label_placeholder: 'Chọn phòng khám',

  category_service_label: 'Danh mục dịch vụ',
  category_service_label_required: 'Vui lòng chọn danh mục dịch vụ',
  category_service_label_placeholder: 'Chọn danh mục dịch vụ',

  service_label: 'Dịch vụ',
  service_label_required: 'Vui lòng chọn dịch vụ',
  service_label_placeholder: 'Chọn dịch vụ',

  treatment_label: 'Thủ thuật',
  treatment_label_required: 'Vui lòng chọn thủ thuật',
  treatment_label_placeholder: 'Chọn thủ thuật',

  reason_label: 'Lý do',
  reason_label_required: 'Vui lòng nhập lý do',
  reason_label_placeholder: 'Nhập lý do',

  duration_label: 'Thời lượng (Phút)',
  duration_label_required: 'Vui lòng chọn thời lượng',
  duration_label_placeholder: 'Chọn thời lượng',

  note_max_length: 'Vượt quá ký tự',
  number_of_character: 'Số ký tự',

  doctor_label: 'Bác sĩ',
  doctor_label_required: 'Vui lòng chọn bác sĩ',
  doctor_label_placeholder: 'Chọn bác sĩ',

  time_label: 'Giờ khám',
  time_label_required: 'Vui lòng chọn giờ khám',
  time_label_placeholder: 'Chọn giờ khám',

  day_label: 'Ngày khám',
  day_label_required: 'Vui lòng chọn ngày khám',
  day_label_placeholder: 'Chọn ngày khám',

  status_label: 'Trạng thái',
  status_label_required: 'Vui lòng chọn trạng thái',
  status_label_placeholder: 'Chọn trạng thái',

  SA: '(Sáng)',
  CH: '(Chiều)',

  note_label: 'Ghi chú',
  note_label_placeholder: 'Nhập nội dung',
  customer_cancel: 'Khách hủy',

  created_by: 'Tạo bởi',
  schedule_info: 'Thông tin lịch hẹn',
  update_info: 'Thông tin cập nhật',
  today: 'Hôm nay',

  search_doctor: 'Tìm bác sĩ',
  all_doctor: 'Tất cả bác sĩ',
  BOOKING_EXISTS: 'Lịch hẹn đã tồn tại',
  BOOKING_DOES_NOT_EXIST: 'Lịch hẹn không tồn tại',
  schedule_appointment: 'Đặt lịch hẹn',
  manage_appointment: 'Quản lý lịch hẹn',
  limit_date: 'Giới hạn 4 tháng',
  select_range_date_required: 'Vui lòng chọn ngày bắt đầu và ngày kết thúc',
  num_kpi: 'KPI',
  check_in_service_plan: 'Kế hoạch số khách hàng check in bởi dịch vụ',
  kpi_plan: 'KPI kế hoạch',
  kpi_actual: 'KPI thực tế',
  item_management: 'Quản lý Sản phẩm - Dịch vụ',
  item_management_content: {
    add_product_property: 'Thêm thuộc tính tài sản',
    add_property: 'Thêm thuộc tính',
    add_property_value: 'Thêm giá trị',
    category: 'Danh mục / Khối dịch vụ',
    enter_job_title_level_id: 'Nhập id cấp bậc',
    all_status: 'Tất cả trạng thái',
    all_item: 'Tất cả Sản phẩm/Dịch vụ',
    add_job_title_level_doctor: 'Thêm cấp bậc bác sĩ',
    service: 'Dịch vụ',
    type: 'Loại',
    request_job_title_level_doctor: 'Yêu cầu cấp bậc bác sĩ',
    job_title_doctor: 'Cấp bậc bác sĩ',
    job_title_level_doctor: 'Cấp bậc bác sĩ thực hiện',
    treatment_use: 'Thủ thuật sử dụng',
    treatment_name: 'Tên thủ thuật',
    step_perform: 'Bước thực hiện',
    time_perform: 'Thời gian thực hiện',
    condition_perform: 'Điều kiện thực hiện',
    skill: 'Kỹ năng',
    material: 'Vật tư',
    supplies: 'Vật tư',
    dental_service: 'Dịch vụ nha khoa',
    dental_product: 'Sản phẩm nha khoa',
    insert_upper_treatment: 'Chèn thủ thuật phía trên',
    insert_lower_treatment: 'Chèn thủ thuật phía dưới',
    insert_upper_step_perform: 'Chèn bước thực hiện phía trên',
    insert_lower_step_perform: 'Chèn bước thực hiện phía dưới',
    add_product_service: 'Thêm Sản phẩm/Dịch vụ',
    description_add_service:
      'Các dịch vụ chăm sóc sức khoẻ răng miệng toàn diện tại Nha Khoa PARKWAY.',
    description_add_product: 'Sản phẩm nha khoa, hỗ trợ bảo vệ răng miệng.',
    description_add_supplies: 'Vật tư Y tế, thiết bị văn phòng',
    description_add_asset:
      'Các thiết bị phục vụ công việc ( Máy tính, máy in .... )',
    description_add_tool:
      'Các công cụ, dụng cụ phục vụ khám chữa bệnh tại khối khám bệnh và phục vụ khối văn phòng',
    description_add_combo:
      'Nhóm Dịch vụ nha khoa chăm sóc sức khoẻ răng miệng toàn diện tại Nha Khoa PARKWAY',
    order_perform: 'Thứ tự thực hiện',
    are_you_want_delete_service: 'Bạn xác nhận xóa dịch vụ',
    are_you_want_delete_product: 'Bạn xác nhận xóa sản phẩm',
    are_you_want_delete_supplies: 'Bạn xác nhận xóa vật tư',
    once_the_service_is_locked_it_cannot_be_recovered:
      'Dịch vụ sau khi xóa sẽ không thể khôi phục lại',
    once_the_product_is_locked_it_cannot_be_recovered:
      'Sản phẩm sau khi xóa sẽ không thể khôi phục lại',
    once_the_supplies_is_locked_it_cannot_be_recovered:
      'Vật tư sau khi xóa sẽ không thể khôi phục lại',
    delete_service: 'Xóa dịch vụ',
    delete_product: 'Xóa sản phẩm',
    delete_supplies: 'Xóa vật tư',
    delete_asset: 'Xóa tài sản',
    delete_tool: 'Xóa công cụ dụng cụ',
    delete_treatment: 'Xóa thủ thuật',
    are_you_want_delete_treatment: 'Bạn xác nhận xóa thủ thuật',
    once_the_treatment_is_locked_it_cannot_be_recovered:
      'Thủ thuật sau khi xóa sẽ không thể khôi phục lại',
    are_you_want_delete_asset: 'Bạn xác nhận xóa tài sản',
    once_the_asset_is_locked_it_cannot_be_recovered:
      'Tài sản sau khi xóa sẽ không thể khôi phục lại',
    are_you_want_delete_tool: 'Bạn xác nhận xóa công cụ dụng cụ',
    once_the_tool_is_locked_it_cannot_be_recovered:
      'Công cụ dụng cụ sau khi xóa sẽ không thể khôi phục lại',
    add_treatment: 'Thêm thủ thuật',
    used_supplies: 'Vật tư sử dụng',
    add_supplies: 'Thêm vật tư',
    select_supplies: 'Chọn vật tư',
    select_treatment: 'Chọn thủ thuật',
    treatment: 'Thủ thuật',
    not_select_supplies: 'Chưa chọn vật tư nào',
    not_select_skill: 'Chưa chọn kỹ năng nào',
    select_job_title: 'Chọn chức danh',
    select_job_title_level: 'Chọn cấp bậc',
    info_service: 'Thông tin dịch vụ',
    info_treatment: 'Thông tin thủ thuật',
    code: 'Mã',
    name: 'Tên',
    name_service: 'Tên dịch vụ',
    enter_name_service: 'Nhập tên dịch vụ',
    condition_success_execute_step: 'Yêu cầu: Đã hoàn thành Bước {{step}}',
    service_code: 'Mã dịch vụ',
    service_code_old: 'Mã dịch vụ cũ',
    enter_service_code: 'Nhập mã dịch vụ',
    enter_service_code_old: 'Nhập mã dịch vụ cũ',
    producer: 'Nhà sản xuất / phân phối',
    select_producer: 'Chọn nhà sản xuất',
    image_type: 'Hình ảnh {{type}}',
    image: 'Hình ảnh',
    select_time_perform: 'Chọn thời gian thực hiện',
    purchase_unit: 'Đơn vị mua',
    select_purchase_unit: 'Chọn đơn vị mua',
    select_category: 'Chọn danh mục / Khối dịch vụ',
    using_unit: 'Đơn vị sử dụng',
    select_using_unit: 'Chọn đơn vị sử dụng',
    unit_used: 'Đơn vị sử dụng',
    select_unit_used: 'Chọn đơn vị sử dụng',
    inventory_management: 'Quản lý tồn kho',

    info_material: 'Thông tin vật tư',
    info_supplies: 'Thông tin vật tư',
    product_name: 'Tên sản phẩm',
    enter_product_name: 'Nhập tên sản phẩm',
    product_code: 'Mã sản phẩm',
    enter_product_code: 'Nhập mã sản phẩm',

    material_name: 'Tên vật tư',
    enter_material_name: 'Nhập tên vật tư',
    material_code: 'Mã vật tư',
    enter_material_code: 'Nhập mã vật tư',
    supplies_name: 'Tên vật tư',
    enter_supplies_name: 'Nhập tên vật tư',
    supplies_code: 'Mã vật tư',
    enter_supplies_code: 'Nhập mã vật tư',

    asset_name: 'Tên công cụ / dụng cụ',
    enter_asset_name: 'Nhập tên công cụ / dụng cụ',
    asset_code: 'Mã công cụ / dụng cụ',
    enter_asset_code: 'Nhập mã công cụ / dụng cụ',

    tools_name: 'Tên tài sản',
    enter_tools_name: 'Nhập tên tài sản',
    tools_code: 'Mã tài sản',
    enter_tools_code: 'Nhập mã tài sản',

    info_asset: 'Thông tin tài sản',
    info_tool: 'Thông tin công cụ / dụng cụ',
    combo_name: 'Tên Combo dịch vụ',
    enter_combo_name: 'Nhập tên Combo dịch vụ',
  },
  treatment_management: 'Quản lý thủ thuật',
  treatment_management_content: {
    request_level_skill: 'Yêu cầu kỹ năng',
    treatment_old_id: 'Mã thủ thuật cũ',
    treatment_name: 'Tên thủ thuật',
    treatment_code: 'ID',
    treatment_execute_time: 'TG báo bệnh nhân',
    treatment_doctor_time: 'TG BS thực hiện',
    treatment_chair_time: 'TG chiếm ghế',
    enter_treatment_name: 'Nhập tên thủ thuật',
    enter_treatment_code: 'Nhập mã thủ thuật',
    enter_treatment_execute_time: 'Nhập thời gian',
    enter_treatment_doctor_time: 'Nhập thời gian',
    enter_treatment_chair_time: 'Nhập thời gian',
    enter_treatment_old_id: 'Nhập mã thủ thuật cũ',
    selected_total_skill: 'Tổng cộng kỹ năng đã chọn',
    are_you_want_delete_treatment: 'Bạn xác nhận xóa dịch vụ',
    once_the_treatment_is_locked_it_cannot_be_recovered:
      'Thủ thuật sau khi xóa sẽ không thể khôi phục lại',
    delete_treatment: 'Xóa thủ thuật',
    please_enter_id_treatment: 'Nhập ID thủ thuật',
    select_type: 'Chọn loại',
  },
  YES: 'Có',
  NO: 'Không',
  material: 'Vật tư',
  tool: 'công cụ / dụng cụ',
  supplies: 'Vật tư',
  tools: 'công cụ / dụng cụ',
  asset: 'Tài sản',
  add_service: 'Thêm dịch vụ',
  create_service: 'Thêm dịch vụ',
  update_service: 'Cập nhật dịch vụ',
  add_product: 'Thêm sản phẩm',
  update_product: 'Cập nhật sản phẩm',
  add_supplies: 'Thêm vật tư',
  update_supplies: 'Cập nhật vật tư',
  add_asset: 'Thêm tài sản',
  update_asset: 'Cập nhật tài sản',
  add_tool: 'Thêm công cụ dụng cụ',
  add_combo: 'Thêm Combo sản phẩm',
  update_tool: 'Cập nhật công cụ dụng cụ',
  quantity: 'Số lượng',
  unit_of_measure: 'Đơn vị tính',
  quantity_must_be_greater_than_0: 'Số lượng phải lớn hơn 0',
  add: 'Thêm',
  empty_list: 'Danh sách trống',
  add_main_image: 'Thêm ảnh chính',
  add_image: 'Thêm ảnh',
  note_size_image: '*Ghi chú: Kích thước ảnh tải lên tối đa {{size}}MB',
  category: 'Danh mục',
  select_category: 'Chọn danh mục',
  search_appointment: 'Tìm kiếm lịch hẹn',
  search_appointment_s_label: 'Nhập họ tên, SĐT bênh nhân....',
  search_appointment_choose_clinic: 'Tất cả phòng khám',
  search_appointment_date_time: 'Ngày & Giờ',
  search_appointment_patient: 'Bệnh nhân',
  search_appointment_dob: 'Ngày sinh',
  search_appointment_doctor: 'Bác sĩ phụ trách buổi hẹn',
  search_appointment_location: 'Địa điểm',
  search_appointment_treatment: 'Điều trị',
  search_appointment_send_sms: 'Gửi SMS (lần)',
  search_appointment_in: 'In',
  add_schedule: 'Thêm lịch hẹn',
  update_schedule: 'Cập nhật lịch hẹn',
  total_schedule: 'Tổng lịch hẹn: ',
  search_appointment_send_all: 'Gửi SMS nhắc lịch cho tất cả danh sách',
  search_appointment_updated_by: 'Cập nhật bởi',
  sms_successfully: 'Gửi tin nhắn thành công',
  search_appointment_doctor_shift: 'Bác sĩ phụ trách ca',
  created_at: 'Ngày tạo',
  sorry_currently_you_cant_download_excel_file:
    'Xin lỗi bạn không thể download excel file lúc này, liên hệ với IT',
  category_management: 'Quản lý danh mục',
  item_category: 'Quản lý danh mục sản phẩm và dịch vụ',
  item_category_content: {
    add: 'Thêm danh mục',
    add_sub: 'Tạo danh mục con',
    name: 'Danh mục',
    please_select_name: 'Nhập tên danh mục',
    are_you_want_delete: 'Bạn xác nhận xoá danh mục',
    create_profile_success: 'Thêm danh mục thành công',
    once_the_profile_is_locked_it_cannot_be_recovered:
      'Danh mục sau khi khoá sẽ không thể khôi phục lại',
    please_enter_id_item: 'Nhập ID dịch vụ',
  },
  report_14: 'Số ca/doanh thu theo SKU',
  report_14_content: {
    title: 'Số ca/doanh thu theo SKU',
    number_of_case: 'Số ca',
    all_branch: 'Tất cả chi nhánh',
    all_age_group: 'Tất cả độ tuổi',
    all_gender: 'Tất cả giới tính',
    all_area: 'Tất cả khu vực',
  },
  case: 'Số ca',
  you_dont_have_permission: `Bạn không có quyền vào chức năng "{{module}}", liên hệ Admin.`,
  not_exist_page:
    'Trang này không tồn tại hoặc bạn không có quyền truy cập, vui lòng liên hệ admin',
  role: 'Role',
  role_content: {
    enter_role: 'Nhập role',
    name_role: 'Tên role',
    permission: 'Quyền',
    update_permission: 'Cập nhật quyền',
    choose_group_permission: 'Chọn nhóm quyền',
    enter_name_permission: 'Nhập tên quyền',
    name_permission: 'Tên quyền',
    total_permission_selected: 'Tổng quyền đã chọn',
    add_role: 'Thêm Role',
    enter_name_role: 'Nhập tên role',
    add_permission: 'Thêm quyền',
    info_permission: 'Thông tin quyền',
    delete_role: 'Xoá role',
    modules: 'Nhóm quyền',
    enter_description_role: 'Nhập mô tả role',
    description_role: 'Mô tả role',
    group_permission: 'Nhóm quyền',
    update_role: 'Cập nhật role',
    not_choose_permission: 'Chưa chọn quyền',
    role_selected_count: 'Số role được chọn',
  },
  role_management: 'Quản lý role',
  all_status: 'Tất cả trạng thái',
  year_number: 'Năm {{year}}',
  tip_price: 'Giá thủ thuật',
  package_name: 'Tên gói dịch vụ',
  package_price: 'Giá trị gói',
  net_amount: 'Tổng thanh toán',
  paid: 'Đã thanh toán',
  contract_company_value: 'Giá trị ký hợp đồng theo hợp đồng công ty',
  contract_clinic_value: 'Giá trị ký hợp đồng theo hợp đồng bên phòng khám',
  contract_code: 'Mã hợp đồng',
  note_print: 'Ghi chú thông tin cho BN muốn xuất hóa đơn',
  note: 'Ghi chú',
  service_amount: 'Số dịch vụ',
  location: 'Cơ sở',
  statistical: 'Thống kê',
  list_braces_tray: 'Danh sách khách hàng bắt đầu gắn khay niềng',
  braces_tray: 'Danh sách khách hàng bắt đầu gắn khay niềng',
  created_successfully: 'Tạo thành công',
  success: 'Thành công',
  once_the_name_is_locked_it_cannot_be_recovered:
    '{{name}} sau khi khoá sẽ không thể khôi phục lại',
  are_you_want_delete_name: 'Bạn có thực sự muốn xoá {{name}}',
  roles: 'Roles',
  rights: 'Quyền',
  job_title_roles: 'Chức danh',
  day_off: 'Day off',
  day_leave_policy: 'Chính sách nghỉ phép',
  day_leave: 'Nghỉ phép',
  CONFLICT_TIME: 'Có xung đột về thời gian',
  waited: 'Đã chờ',
  inventory: 'Kho',
  dayoff: 'Day off',
  dayleave: 'Nghỉ phép',
  profile_management: 'Quản lý nhân viên',
  id: 'ID',
  select_all: 'Chọn tất cả',
  unselect_all: 'Bỏ tất cả',
  copy_success: 'Copy thành công!',
  skill_title: 'Kĩ năng',
  my_profile: 'Hồ sơ của tôi',
  group_service: 'Nhóm dịch vụ',
  select_debt_date: 'Ngày phát sinh gói',
  select_debt_date_all: 'Ngày phát sinh',
  old_password: 'Mật khẩu cũ',
  enter_old_password: 'Nhập mật khẩu cũ',
  new_password: 'Mật khẩu mới',
  enter_new_password: 'Nhập mật khẩu mới',
  confirm_new_password: 'Xác nhận mật khẩu mới',
  enter_confirm_new_password: 'Nhập xác nhận mật khẩu mới',
  the_new_password_that_you_entered_do_not_match:
    'Mật khẩu xác nhận không trùng khớp!',
  new_password_need_different_old_password:
    'Mật khẩu mới vui lòng khác mật khẩu cũ!',
  change_password_success_and_please_re_login:
    'Bạn đã đổi mật khẩu thành công, vui lòng đăng nhập lại!',
  old_password_is_incorrect: 'Mật khẩu cũ không đúng',
  wrong_username_or_password: 'Sai tài khoản hoặc mật khẩu.',
  with_error_please_contact_with_admin:
    'Đã có lỗi xảy ra, vui lòng liên hệ admin IT',
  your_session_has_expired_please_login_again:
    'Phiên đang nhập đã hết, vui lòng đăng nhập lại!',
  enter_email_or_phone: 'Nhập số điện thoại hoặc email',
  dental_treatment_management: 'Quản lý điều trị',
  patient_record_management: 'Quản lý hồ sơ bệnh nhân',
  enter_search_patient: 'Nhập tên bệnh nhân, số điện thoại, ...',
  add_patient_record: 'Thêm hồ sơ bệnh nhân',
  update_patient_record: 'Cập nhật hồ sơ bệnh nhân',
  msbn: 'MSBN',
  enter_patient_code: 'Nhập mã số bệnh nhân',
  choose_province: 'Chọn tỉnh',
  choose_district_or_city: 'Chọn quận/huyện/thành phố',
  choose_ward: 'Chọn phường/xã/thị trấn',
  enter_address: 'Nhập địa chỉ',
  customer_source: 'Nguồn khách hàng',
  choose_source: 'Chọn nguồn',
  important_note: 'Ghi chú quan trọng',
  enter_note: 'Nhập ghi chú',
  patient_info: 'Thông tin bệnh nhân',
  update_by: 'Cập nhật bởi',
  see_history_update_note: 'Xem lịch xử cập nhật ghi chú',
  history_update_patient_record: 'Lịch sử cập nhật hồ sơ bệnh nhân',
  lasted_appointment: 'Lịch hẹn gần nhất',
  attending_physician: 'Bác sĩ phụ trách ca',
  managment: 'Quản lý',
  birthday: 'Ngày sinh',
  enter_phone: 'Nhập số điện thoại',
  enter_first_name: 'Nhập họ',
  enter_last_name: 'Nhập tên',
  enter_email: 'Nhập email',
  please_select_gender: 'Vui lòng chọn giới tính',
  choose_area: 'Chọn khu vực',
  choose_clinic: 'Chọn phòng khám',
  select_birthday: 'Chọn ngày sinh',
  district: 'Quận/huyện/thành phố',
  ward: 'Phường/xã/thị trấn',
  utilities: 'Tiện ích',
  lasted_appoinment: 'Lịch hẹn gần nhất',
  qr_generate: 'Tạo mã QR',
  input_link_you_want: 'Nhập link hoặc điều gì bạn muốn',
  generate: 'Tạo',
  value_qr: 'Giá trị mã QR',
  reset_QR: 'Tạo mã khác',
  download: 'Download',
  selecting: 'Đang chọn',
  please_select_employee: 'Vui lòng chọn nhân viên',
  date_recall: 'Ngày hẹn gọi lại',
  unit_price: 'Đơn giá',
  treatment_fee: 'Phí điều trị',
  vat_code: 'Mã VAT',
  refund: 'Hoàn tiền',
  cash: 'Tiền mặt',
  card: 'Trả thẻ',
  card_type: 'Loại thẻ',
  transfer: 'Chuyển khoản',
  transfer_type: 'Loại chuyển khoản',
  app_momo: 'Momo',
  payoo_link: 'Payoo Link',
  select_service_treatment_required: 'Vui lòng trọn gói hoặc thủ thuật',
  sync: 'Đồng bộ',
  debt: 'Công nợ',
  sync_for_up_to_one_day: 'Đồng bộ hóa tối đa một ngày',
  sync_successfully: 'Đồng bộ thành công',
  choose_service: 'Chọn dịch vụ',
  booking_website_report: 'Báo cáo số lượt đặt lịch từ website',
  arrivals: 'Lượt',
  number_of_booking_in_many_days: 'Số lượt đặt lịch trong {{count}} ngày',
  time_from_to: 'Thời gian: {{from}} - {{to}}',
  number_arrivals: 'Số lượt',
  export_excel_sendmail_message: 'Tệp tin sẽ được gửi vào email của bạn',
  profile_patient_record: 'Thông tin bệnh nhân',
  date_time: 'Ngày giờ',
  main_content: 'Nội dung',
  doctor_in_charge_of_the_shift: 'Bác sĩ phụ trách ca',
  prefix: 'Prefix',
  appointment: 'Lịch hẹn',
  call_reminder_schedule: 'Lịch nhắc gọi',
  purchase_dental_products_services: 'Mua sản phẩm/ dịch vụ nha khoa',
  payment_debt: 'Nợ thanh toán',
  order_id: 'Mã đơn hàng',
  monthly_installment_plan: 'Trả góp theo tháng',
  info_payment: 'Thông tin thanh toán',
  late_payment_period: 'Kỳ thanh toán trễ hạn',
  total_amount_due: 'Tổng số tiền thanh toán',
  payment_method: 'Phương thức thanh toán',
  add_payment_method: 'Thêm phương thức thanh toán',
  issue_invoice: 'Xuất hoá đơn',
  detail_payment: 'Chi tiết thanh toán',
  total_payment_required: 'Tổng tiền cần thanh toán',
  amount_paid_by_customer: 'Khách đưa',
  enter_amount_paid_by_customer: 'Nhập số tiền khách đưa',
  excess_money: 'Tiền thừa',
  total_payment_made: 'Tổng tiền đã thanh toán',
  late_payment_processing: 'Thanh toán kỳ trễ hạn',
  complete_settlement: 'Tất toán toàn bộ',
  payment_option: 'Hình thức thanh toán',
  e_wallet: 'Ví điện tử',
  choose_payment_method: 'Chọn phương thức thanh toán',
  enter_money: 'Nhấp số tiền',
  payment: 'Thanh toán',
  add_product_service: 'Thêm sản phẩm/dịch vụ',
  close: 'Đóng',
  invoice: 'Hoá đơn',
  total_invoice: 'Tổng hoá đơn',
  alternative_application_program: 'Chương trình áp dụng khác',
  installment_payment: 'Trả góp',
  payment_in_stages: 'Theo giai đoạn',
  discount: 'Giảm giá',
  product_does_not_support_installment_payment: 'Sản phẩm không hỗ trợ trả góp',
  total: 'Tổng',
  total_amount: 'Thành tiền',
  number_of_products_services: 'Số sản phẩm & dịch vụ',
  subtotal_amount: 'Số tiền tạm tính',
  promotion: 'Khuyến mãi',
  disount_code: 'Mã giảm giá',
  enter_discount_code: 'Nhập mã giảm giá',
  total_payment: 'Tổng tiền',
  program: 'Chương trình',
  add_alternative_application_program: 'Thêm chương trình áp dụng',
  select_program: 'Chọn chương trình',
  select_payment_method: 'Chọn phương thức thanh toán',
  enter_name_company: 'Nhập tên công ty',
  tax_id: 'Mã số thuế',
  enter_tax_id: 'Nhập mã số thuế',
  total_amount_payment: 'Tổng tiền thanh toán',
  image_patient: 'Hình ảnh bệnh nhân',
  add_treatment_procedure_folder: 'Thêm thư mục thủ thuật điều trị',
  exist_folder_name: 'Đã tồn tại folder tên {{name}}',
  note_maximum_image_upload_size_is_5_MB:
    'Ghi chú: Kích thước ảnh tải lên tối đa 5 MB',
  provider: 'Nhà cung cấp',
  provider_management: 'Quản lý nhà cung cấp',
  provider_add: 'Thêm nhà cung cấp',
  provider_update: 'Cập nhật nhà cung cấp',
  provider_delete: 'Xóa nhà cung cấp',
  provider_form_required: 'Vui lòng nhập {{field}}',
  name: 'Tên',
  contact: 'Liên hệ',
  bankinfo: 'Thông tin chuyển khoản',
  TIN: 'Mã số thuế',

  treatment_schedule: 'Lịch trình điều trị',
  total_amount_service: 'Tổng tiền dịch vụ',
  paid_amount: 'Tiền đã thanh toán',
  refund_amount: 'Đã hoàn tiền',
  remaining_amount: 'Còn lại',
  patient_journey: 'Hành trình bệnh nhân',
  emergency_contact: 'Liên hệ khẩn cấp',
  next_of_kin: 'Thân nhân',
  relatives: 'Thân nhân',
  call_schedule: 'Lịch gọi khác',
  add_call_schedule: 'Thêm lịch gọi khác',
  dental_health_history: 'Lịch sử khảo sát sức khoẻ răng miệng',
  laboratory_results: 'Kết quả lâm sàn',
  transaction_history: 'Lịch sử giao dịch',
  payment_history: 'Lịch sử thanh toán',
  under_treatment: 'Đang điều trị',
  service_refund: 'Hoàn trả dịch vụ',
  treatment_information: 'Thông tin điều trị',
  treating_physician: 'Bác sĩ lập phác đồ',
  no_treating_physician: 'Chưa có bác sĩ lập phác đồ',
  treatment_plan: 'Phác đồ điều trị',
  no_assigned_physician: 'Chưa có bác sĩ phụ trách',
  assigned_physician: 'Bác sĩ phụ trách',
  approved: 'Đã được duyệt',
  approve: 'Duyệt',
  treatment_phase: 'Giai đoạn điều trị',
  phase_attending_physician: 'Bác sĩ phụ trách giai đoạn',
  phase_approver: 'Người duyệt giai đoạn',
  add_phase: 'Thêm giai đoạn',
  send_payment_SMS: 'Gửi SMS thanh toán',
  send_treatment_receipt_email: 'Email phiếu điều trị',
  print_treatment_receipt: 'In phiếu điều trị',
  print_contract: 'In hợp đồng',
  add_appointment: 'Thêm buổi hẹn',
  appointment_session: 'Buổi hẹn',
  symptoms_diagnosis: 'Triệu chứng/chẩn đoán',
  enter_symptoms_diagnosis: 'Nhập triệu chứng/chẩn đoán',
  treatment_direction: 'Phương hướng điều trị',
  enter_treatment_direction: 'Nhập phương hướng điều trị',
  procedures_within_appointment: 'Các thủ thuật trong buổi hẹn',
  equipment_information: 'Thông tin vật tư',
  professional_exchange_with_physician: 'Trao đổi với bác sĩ chuyên môn',
  image_size: 'Kích thước hình ảnh (JPEG, PNG)',
  document_size: 'Kích thước tài liệu (Word, Excel, PDF) tối đa 10Mb',
  upload_size_max_10_mb:
    'Kích thước hình ảnh (JPEG, PNG) và tài liệu (Word, Excel, PDF) tối đa 10Mb',
  perform_procedure: 'Thực hiện thủ thuật',
  compress: 'Thu gọn',
  cancel_approval: 'Đã huỷ duyệt',
  not_available: 'Chưa có',
  complete: 'Hoàn thành',
  completed: 'Đã hoàn tất',
  in_treatment: 'Đã điều trị',
  cancelled: 'Đã huỷ',
  suspended: 'Tạm ngưng',
  pending_execution: 'Chờ thực hiện',
  payment_information: 'Thông tin thanh toán',
  refund_money: 'Hoàn tiền',
  code: 'Mã',
  payment_code: 'Mã thanh toán',
  payment_date: 'Thời hạn thanh toán',
  material_import: 'Nhập vật liệu',
  material_import_management: 'Quản lý nhập vật liệu',
  material_import_code: 'Mã nhập vật liệu',
  material_import_add: 'Thêm nhập vật liệu',
  material_import_update: 'Cập nhật nhập vật liệu',
  material_import_delete: 'Xóa nhập vật liệu',
  material_import_form_required: 'Vui lòng nhập {{field}}',
  material_management: 'Quản lý vật liệu',
  invoice_number: 'Số hoá đơn',
  invoice_symbol: 'Ký hiệu hóa đơn',
  invoice_date: 'Ngày hoá đơn',
  invoice_price_tax: 'Thành tiền thuế',
  unit_import: 'Đơn vị nhập',
  total_price: 'Tổng tiền',
  unit_use: 'Đơn vị sử dụng',
  unit_use_import: 'Số đơn vị sử dụng / đơn vị nhập',
  date_import: 'Ngày nhận hàng',
  select: 'Chọn',
  created_date: 'Ngày lập',
  material_category: 'Danh mục vật liệu',
  material_vat: 'Thuế suất',
  detail: 'Chi tiết',
  material_group: 'Nhóm vật tư',
  provider_contact: 'Contact nhà cung cấp',
  material_purpose: 'Công dụng',
  patient_code: 'Mã KH',
  material_export: 'Xuất vật liệu',
  material_category_delete: 'Xoá danh mục vật liệu',
  material_category_update: 'Cập nhật danh mục vật liệu',
  provider_code: 'Mã nhà cung cấp',
  doctors_and_nurses_perform: 'Bác sĩ & Y tá thực hiện',
  doctors_perform: 'Bác sĩ thực hiện',
  nurses_perform: 'Y tá thực hiện',
  stop_performing: 'Dừng thực hiện',
  pending_payment: 'Chờ thanh toán',
  not_occurred: 'Chưa diễn ra',
  occurred: 'Diễn ra',
  have_problem: 'Có vấn đề',
  update_appointment_session: 'Cập nhật buổi hẹn',
  name_appointment_session: 'Tên buổi hẹn',
  delete_appointment_session: 'Xoá buổi hẹn',
  update_treatment_phase: 'Cập nhật giai đoạn',
  delete_treatment_phase: 'Xoá giai đoạn',
  add_appointment_session: 'Thêm buổi hẹn',
  enter_content: 'Nhập nội dung',
  content: 'Nội dung',
  attached: 'Đính kèm',
  amount: 'Số tiền',
  schedule_without_paid: 'Lịch hẹn 0Đ',
  patient_arrive_at_the_clinic: 'Bệnh nhân đến phòng khám',
  the_patient_is_counseled_by_a_counselor:
    'Bệnh nhân được tư vấn bởi tư vấn viên',
  the_patient_undergoes_treatment: 'Bệnh nhân thực hiện điều trị',
  the_patient_leaves: 'Bệnh nhân ra về',
  performer: 'Người thực hiện',
  enter_phase_name: 'Thêm tên giai đoạn',
  phase_name: 'Tên giai đoạn',
  enter_name_appointment_session: 'Nhập tên buổi hẹn',
  add_doctor: 'Thêm bác sĩ',
  add_nurse: 'Thêm y tá',
  appointment_session_information: 'Thông tin buổi hẹn',
  enter_name_doctor: 'Nhập tên bác sĩ',
  create_treatment_plan: 'Tạo phác đồ điều trị',
  not_have: 'Chưa có',
  difficulty_level_of_the_shift: 'Mức độ khó của ca',
  type_treatment_plan: 'Loại phác đồ',
  braces_tag: 'Tag chỉnh nha',
  select_braces_tag: 'Chọn tag chỉnh nha',
  diagnosis: 'Chẩn đoán',
  anteroposterior_direction_a: 'Chiều trước - sau (A)',
  vertical_direction_b: 'Chiều dọc (B)',
  vertical_direction_a: 'Chiều dọc (A)',
  tooth_size_and_morphology_mm: 'Kích thước răng và hình thể (mm)',
  therapeutic: 'Mục tiêu điều trị',
  additional_troubleshooting: 'Thêm vấn đề khắc phục',
  part_number: 'Phần {{part}}',
  procedures_equipment: 'Phác đồ & Khí cụ',
  treatment_stages: 'Các giai đoạn điều trị',
  basic_forecast: 'Dự trù cơ bản',
  emerging_issues_solutions_approach: ' Vấn đề phát sinh & hướng giải quyết',
  date_of_issue_occurrence: 'Ngày phát sinh vấn đề',
  treatment_request: 'Yêu cầu điều trị',
  incident_forecast: 'Dự trù phát sinh',
  time_elapsed_since_the_beginning: 'Thời gian từ khi bắt đầu',
  resolution_direction: 'Hướng giải quyết',
  emerging_issue: 'Vấn đề phát sinh',
  professional_medical_opinion: 'Ý kiến bác sĩ chuyên môn',
  teeth_with_pathology: 'Răng có bệnh lý',
  tooth_loss: 'Mất răng',
  implant_placement: 'Gắn implant',
  tooth_with_indication_for_extraction: 'Răng/Chân răng có chỉ định nổ',
  remaining_tooth_roots: 'Còn chân răng',
  tooth_bridge: 'Cầu răng',
  delete_tooth_condition: 'Xoá tình trạng răng',
  orthodontic_without_paid: 'Bệnh nhân chỉnh nha 0đ',
  treatment_date: 'Ngày phát sinh thủ thuật',
  treatment_count_month: 'Số tháng điều trị tính đến hiện tại',
  orthodontic_patient_list_zero_payment: 'Danh sách bệnh nhân chỉnh nha 0đ',
  from_year: 'Từ năm',
  from_quarter: 'Từ quý',
  to_year: 'Đến năm',
  to_quarter: 'Đến quý',
  total_patient: 'Tổng bệnh nhân',
  patient_id: 'Mã bệnh nhân',
  appointment_date: 'Ngày hẹn',
  package_level: 'Mức độ gói',
  orthodontic_schedule_zero_payment: 'Lịch chỉnh nha 0đ',
  patient_with_orthodontic_appointment_0đ: 'Bệnh nhân có lịch chỉnh nha 0đ',
  ineffective_doctor: 'Bác sĩ không hiệu quả',
  ineffective_clinic: 'Phòng khám không hiệu quả',
  total_month: 'Tổng số tháng',
  cure_is_needed_for_the_patient: 'Bệnh nhân cần kết thúc chuyên môn',
  the_patient_needs_to_alert_the_doctor: 'Bệnh nhân cần cảnh báo bác sĩ',
  difficulty_number: 'Mức độ {{level}}',
  warning_doctor: 'Cảnh báo bác sĩ',
  warning_clinic: 'Cảnh báo phòng khám',
  add_contact: 'Thêm liên hệ',
  relationship: 'Quan hệ',
  already_a_patient: 'Đã là bệnh nhân',
  not_a_patient_yet: 'Chưa là bệnh nhân',
  patient_record: 'Hồ sơ bệnh nhân',
  select_patient_record: 'Chọn hồ sơ bệnh nhân',
  update_contact: 'Cập nhật liên hệ',
  add_relatives: 'Thêm thân nhân',
  update_relatives: 'Cập nhật thân nhân',
  reason: 'Lý do',
  call_reminder: 'Người nhắc',
  add_survey: 'Thêm khảo sát',
  dental_health_survey: 'Khảo sát sức khoẻ răng miệng',

  regular_toothbrush: 'Bàn chải thường',
  electric_toothbrush: 'Bàn chải điện',
  dental_floss: 'Chỉ nha khoa',
  toothpick: 'Tăm xỉa răng',
  interdental_brush: 'Bàn chải kẽ',
  tongue_scraper: 'Nạo lưỡi',
  mouthwash: 'Nước súc miệng',
  water_flosser: 'Tăm nước',

  do_you_have_any_areas_where_food_tends_to_get_stuck_or_impacted:
    'Răng anh / chị có chỗ nào hay bị giắt / mắc thức ăn vào không?',
  do_you_have_any_areas_that_need_to_be_re_filled_or_do_you_have_any_old_fillings_that_need_to_be_checked:
    'Răng anh / chị có chỗ nào cần trám lại hay có vết trám cũ nào cần kiểm tra không?',
  do_your_gums_bleed_when_you_brush_or_floss:
    'Nướu của anh / chị có bị chảy máu khi đánh răng / dùng chỉ nha khoa không?',
  have_you_ever_had_gingivitis_or_periodontitis:
    'Anh / chị có từng mắc bệnh viêm lợi hay bệnh nha chu không?',
  when_was_your_last_dental_cleaning:
    'Lần cuối anh / chị đi lấy cao răng là khi nào?',
  do_you_experience_any_unpleasant_taste_in_your_mouth_or_unpleasant_bad_breath:
    'Anh / chị có có gặp tình trạng miệng có vị khó chịu hoặc hơi thở có mùi hôi khó chịu không?',
  how_many_times_a_day_do_you_usually_brush_your_teeth:
    'Anh / chị thường đánh răng một ngày mấy lần?',
  oral_hygiene_tools_you_usually_use:
    'Anh / chị thường vệ sinh răng miệng bằng những dụng cụ nào?',
  are_you_currently_using_any_prescription_toothpaste_or_mouthwash:
    'Anh / chị có đang dùng loại kem đánh răng / nước súc miệng nào theo toa?',
  would_you_like_a_consultation_for_teeth_whitening:
    'Anh / chị có muốn được tư vấn làm trắng răng không?',
  are_there_any_spots_or_stains_on_your_teeth_that_you_are_not_happy_with:
    'Trên răng anh / chị có đốm hoặc vết ố nào khiến anh / chị không hài lòng?',
  have_you_ever_had_orthodontic_treatment_braces:
    'Anh / chị đã từng chỉnh nha ( niềng răng / nắn chỉnh răng ) bao giờ chưa?',
  would_you_like_to_have_straighter_teeth:
    'Anh / chị đã từng chỉnh nha ( niềng răng / nắn chỉnh răng ) bao giờ chưa?',
  do_you_often_bite_your_cheek_when_chewing:
    'Khi nhai, anh / chị có hay bị cắn vào má không?',
  do_you_often_get_mouth_ulcers: 'Anh / chị có hay bị nhiệt miệng không?',
  are_you_currently_using_any_special_dental_appliances:
    'Anh / chị có đang dùng khí cụ nha khoa đặc biệt nào không ( Hàm duy trì / máng chống nghiến răng / khí cụ chống ngáy... )?',
  do_you_snore_or_have_jaw_pain_especially_in_the_morning:
    'Anh / chị có ngáy hay bị mỏi hàm, đặc biệt là vào buổi sáng không?',
  do_you_wear_dentures_or_partial_dentures_at_home:
    'Ở nhà, anh / chị có đeo hàm giả tháo lắp hay hàm giả bán phần không?',
  general_info: 'Thông tin chung',
  time: 'Thời gian',
  create_by: 'Người tạo',
  survey_information: 'Nội dung khảo sát',
  product_service: 'Sản phẩm/ Dịch vụ',
  current_status: 'Hiện trạng',
  result: 'Kết quả',
  history: 'Lịch sử',
  updated_history: 'Lịch sử cập nhật',
  created_new: 'Mới tạo',
  create_new: 'Tạo mới',
  cancel_booking: 'Hủy lịch hẹn',
  employee: 'Nhân viên',
  enter_quantity: 'Nhập số lượng',
  old_provider_management: 'Quản lý nhà cung cấp bản cũ',
  supplier_code: 'Mã ncc',
  old_supplier_code: 'Mã ncc cũ',
  bank_account: 'Số TK ngân hàng',
  bank_name: 'Ngân hàng',
  supplier: 'Nhà cung cấp',
  supplier_name: 'Tên nhà cung cấp',
  total_page: 'Tổng {{total}} mục',
  warehouse_category: 'Quản lý danh mục kho',
  warehouse: 'Kho',
  warehouse_name: 'Tên kho',
  warehouse_code: 'Mã kho',
  warehouse_road_code: 'Mã kho đi đường',
  warehouse_add: 'Thêm kho',
  warehouse_update: 'Cập nhật kho',
  warehouse_import: 'Kho nhập',
  person_request: 'Người yêu cầu',
  block: 'Tạm ngưng',
  draft: 'Nháp',
  purchase_order_pending: 'Chờ duyệt',
  purchase_order_confirmed: 'Đã duyệt',
  purchase_order_cancel: 'Từ chối',
  purchase_order_sent_to_supplier: 'Đã gửi NCC',
  purchase_order_done: 'Đã hoàn thành',
  purchase_order_received: 'Đã nhận hàng',
  purchase_order_returned: 'Đã trả hàng',
  order: 'Đơn hàng',
  _edit: 'Chỉnh sửa',
  patient_outdated: 'Danh sách bệnh nhân được chỉnh sửa qua ngày',
  updated_at: 'Ngày cập nhật',
  updated_person: 'Người cập nhật',
  updated_by: 'Cập nhật bởi',
  net_amount_edited: 'Tổng thanh toán đã được sửa',
  date_incorrect: 'Ngày sai',
  warehouse_purchase_management: 'Quản lý thu mua',
  purchase_order_receive: 'Nhận hàng',
  purchase_order_re_order: 'Đặt lại đơn hàng',
  purchase_order_print: 'In đơn đặt hàng',
  purchase_order: 'Đơn đặt hàng',
  purchase_order_change_message:
    'Trạng thái {{currentStatus}} chỉ được chuyển sang {{newStatus}}',
  patient_being_examined: 'Bệnh nhân đang khám',
  view_full_profile: 'Xem hồ sơ đầy đủ',
  medical_record: 'Bệnh án',
  doctor_info: 'Thông tin bác sĩ',
  procedure_implementation: 'Thủ thuật thực hiện',
  patient_appointments_today: 'Lịch hẹn bệnh nhân hôm nay',
  news: 'Tin tức',
  see: 'Xem',
  pending_approval: 'Chờ duyệt',
  statistic_month_number: 'Thống kê tháng {{month}}',
  closing_rate: 'Tỉ lệ chốt ca',
  number_of_orthodontic_patients_under_treatment: 'Số bệnh nhân đang điều trị',
  today_work: 'Công việc hôm nay',
  name_work: 'Tên công việc',
  doing: 'Đang làm',
  pending: 'Đang chờ',
  work_this_week: 'Công việc tuần này',
  in_examination: 'Đang khám',
  has_arrived: 'Đã tới',
  has_not_arrived: 'Chưa tới',
  dashboard_doctor: 'Dashboard bác sĩ',
  dashboard_bod: 'Dashboard BOD',
  dashboard_nurse: 'Dashboard y tá',
  dashboard_consultation: 'Dashboard tư vấn',
  warehouse_purchase: 'Quản lý thu mua',
  sku: 'SKU',
  minimum_one_time_payment: 'Thanh toán tối thiểu trả một lần',
  minimum_installment_payment_in_clinic:
    'Thanh toán tối thiểu trả góp tại phòng khám',
  end_of_shift_payment: 'Thanh toán chốt ca',
  is_using: 'Đang sử dụng',
  not_using: 'Nhưng sử dụng',
  required_doctor_level: 'Cấp bậc bác sĩ yêu cầu',
  enter_name_product_service: 'Nhập tên sản phầm dịch vụ',
  service_type: 'Loại dịch vụ',
  choose_service_type: 'Nhập loại dịch vụ',
  enter_barcode: 'Nhập barcode',
  enter_amount_paid: 'Nhập số tiền',
  warranty_month: 'Tháng bảo hành',
  enter_warranty_month: 'Nhập tháng bảo hành',
  description_service: 'Mô tả dịch vụ',
  description_warranty: 'Mô tả bảo hành',
  enter_number_day: 'Nhập số ngày',
  name_combo_service: 'Tên Combo dịch vụ',
  combo_info: 'Thông tin combo',
  select_service_product: 'Chọn dịch vụ/ sản phẩm',
  patient_profile: 'Hồ sơ bệnh nhân',
  new_patient_profile: 'Tạo mới hồ sơ bệnh nhân',
  current: 'Hiện tại',
  patient_profile_required: 'Vui lòng chọn hồ sơ bệnh nhân',
  send_sms: 'Gửi SMS',
  select_clinic: 'Chọn chi nhánh',
  select_doctor: 'Chọn bác sĩ',
  treatment_month: 'Số tháng điều trị',
  total_orthodontic: 'Số lịch hẹn chỉnh nha',
  agv_appointment_every_month: 'Số lịch hẹn TB mỗi tháng',
  orthodontic_patient_scheduling_density:
    'Mật độ đặt lịch của bệnh nhân chỉnh nha',
  unfinished: 'Chưa kết thúc',
  shift_start_time: 'Ngày bắt đầu ca',
  shift_end_time: 'Ngày kết thúc ca',
  please_select_unit_parent: 'Vui lòng chọn phòng ban trực thuộc',
  document_1: 'Tài liệu',
  general_dentistry: 'Nha khoa tổng quát',
  cosmetic_dentistry: 'Nha khoa thẩm mỹ',
  pediatric_dentistry: 'Nha khoa trẻ em',
  form_form: 'Form / Biểu mẫu',
  policy: 'Chính sách',
  job_lead: 'Đầu mối công việc',
  see_all: 'Xem tất cả',
  rate_close_after_general_examination: 'Tỉ lệ chốt ca sau khám tổng quát',
  close_after_general_examination: 'Chốt ca sau khám tổng quát',
  close_after_IP_examination: 'Chốt sau khám IP',
  close_after_orthodontic_examination: 'Chốt sau khám phục hình',
  approve_flow: 'Luồng duyệt',
  approve_treatment_plan: 'Duyệt phác đồ điều trị',
  approve_treatment_phase: 'Duyệt giai đoạn điều trị',
  enter_job_title: 'Nhập chức danh',
  select_department: 'Chọn phòng ban',
  approve_type: 'Loại duyệt',
  select_approve_type: 'Chọn loại duyệt',
  sender_job_title: 'Chức danh người gửi',
  select_sender_job_title: 'Chọn chức danh người gửi',
  approver_job_title: 'Chức danh người duyệt',
  select_approver_job_title: 'Chọn chức danh người duyệt',
  add_approve_flow: 'Thêm luồng duyệt',
  add_sender: 'Thêm người gửi',
  total_selected: 'Tổng đã chọn',
  total_group_selected: 'Tổng nhóm đã chọn',
  reject: 'Từ chối',
  level: 'Mức độ',
  patient: 'Bệnh nhân',
  date: 'Ngày',
  treatment_plan_type: 'Loại phác đồ',
  phase: 'Giai đoạn',
  wait_for_approval: 'Chờ duyệt',
  value_level: 'Giá trị cấp bậc',
  enter_value_level: 'Nhập giá trị cấp bậc',
  monthly_roster: 'Monthly Roster',
  roster: 'Roster',
  report_leave: 'Báo cáo nghỉ phép',
  report_doctor_work_time: 'Báo cáo giờ công bác sĩ',
  all_job_title: 'Tất cả chức danh',
  total_working: 'Tổng công',
  total_working_day: 'Tổng ngày công',
  total_leave_day: 'Tổng ngày nghỉ',
  actual_day: 'Ngày thực tế',
  salary_day: 'Ngày tính lương',
  leave_type: 'Loại nghỉ phép',
  remaining_old_year: 'Năm cũ còn lại',
  annual_leave: 'Nghỉ phép năm (AL)',
  sick_leave: 'Nghỉ phép ốm (SL)',
  available_in_quarter_2: 'Khả dụng trong quý 2',
  used_to_current_month: 'Đã sử dụng tới tháng hiện tại',
  remaining_in_quarter_2: 'Còn lại trong quý 2',
  available: 'Khả dụng',
  used: 'Đã dùng',
  remaining: 'Còn lại',
  follow_weekend_leave: 'Theo dõi ngày nghỉ cuối tuần',
  number_of_days_in_month: 'Số ngày trong tháng',
  follow_leave_quota_in_month: 'Theo dõi định mức ngày nghỉ trong tháng',
  half_day: 'Nửa ngày',
  full_day: 'Cả ngày',
  new_year: 'Năm mới',
  express: 'Express',
  standard: 'Standard',
  core: 'Core',
  super_center: 'Super Center',
  flagship: 'Flagship',
  clinic_type: 'Loại phòng khám',
  select_clinic_type: 'Chọn loại phòng khám',
  name_doctor: 'Tên bác sĩ',
  working_hour_month: 'Giờ công tháng',
  appointment_hour: 'Giờ đặt lịch',
  rate: 'Tỉ lệ',
  working_hour_day: 'Giờ công ngày',
  maximum_leave_weekend: 'Tối đa chỉ được nghỉ 2 ngày cuối tuần/tháng',

  working_hour_month_appointment_hour: 'Giờ công tháng / Giờ đặt lịch',
  working_hour_date_appointment_hour: 'Giờ công ngày / Giờ đặt lịch',
  warehouse_purchase_add: 'Tạo đơn đặt hàng',
  warehouse_purchase_update: 'Cập nhật đơn đặt hàng',
  save_daft: 'Lưu nháp',
  create: 'Tạo',
  company_name: 'Công ty cổ phần y tế PW - PW Medical Joint Stock Company',
  company_address:
    'Tầng 1, 215 Nam Kỳ Khởi Nghĩa, Phường 7, Quận 3, Tp. Hồ Chí Minh - MST: 03155575273',
  buy_info: 'Thông tin mua hàng',
  purchaser: 'Người đặt hàng',
  receiver: 'Người nhận hàng',
  warehouse_receive: 'Kho nhận hàng',
  estimate_delivery_date: 'Ngày dự kiến giao hàng',
  payment_due_date: 'Ngày đến hạn thanh toán dự tính',
  contact_name: 'Người liên hệ',
  sent_to_supplier: 'Gửi đơn hàng cho nhà cung cấp',
  order_info: 'Thông tin đơn hàng',
  sample_file: 'File mẫu',
  goods_add: 'Thêm hàng hoá',
  goods: 'Hàng hoá',
  goods_code: 'Mã hàng hoá',
  goods_name: 'tên hàng hoá',
  goods_require: 'Vui lòng thêm hàng hoá',
  goods_please_input_quantity_unit_price:
    'Vui lòng nhập số lượng và đơn giá của hàng hóa',
  goods_please_input_quantity: 'Vui lòng nhập số lượng',
  vat: 'Thuế',
  select_goods_category: 'Chọn danh mục hàng hoá',
  unselect: 'Bỏ chọn',
  order_total: 'Tổng đơn hàng',
  order_discount: 'Chiết khấu',
  please_input_all_field: 'Vui lòng nhập đủ các trường thông tin',
  view_detail: 'Xem chi tiết',
  goods_receipt_info: 'Thông tin nhập hàng',
  goods_receipt_ticket: 'Phiếu nhập hàng',
  buy_ticket: 'Phiếu mua hàng',
  goods_receipt_reason: 'Lý do nhập hàng',
  import_from_purchase_order: 'Lấy từ phiếu mua',
  excel: 'Excel',
  warehouse_inventory_management: 'Quản lý hàng hoá theo kho',
  warehouse_inventory: 'Hàng hoá theo kho',
  available_quantity: 'Số lượng khả dụng',
  road_available_quantity: 'Số lượng đi đường',
  all_type: 'Tất cả các loại',
  transferred_goods: 'Chuyển kho',
  goods_receipt: 'Nhập kho',
  transferred_goods_code: 'Mã chuyển kho',
  import_error: 'Import lỗi',
  goods_existed: 'Hàng hóa đã tồn tại',
  warehouse_import_amount: 'SL nhập kho',
  warehouse_goods_issue: 'Phiếu chuyển kho chung',
  warehouse_goods_issue_reason: 'Lý do chuyển',
  warehouse_export: 'Kho xuất',
  warehouse_goods_issue_code: 'Mã chuyển kho',
  warehouse_goods_issue_delivering: 'Đang chuyển',
  warehouse_goods_issue_short_received: 'Nhận thiếu',
  warehouse_goods_issue_correct_received: 'Đã nhận đủ',
  warehouse_goods_issue_over_received: 'Nhận dư',
  warehouse_goods_issue_add_return: 'Thêm chuyển ngược',
  warehouse_goods_issue_add: 'Tạo phiếu chuyển kho',
  warehouse_goods_issue_info: 'Thông tin chuyển kho',
  warehouse_select: 'Chọn kho',
  warehouse_goods_issue_issuer: 'Nhân viên xuất hàng',
  warehouse_goods_issue_receiver: 'Nhân viên nhập hàng',
  created_date_ticket: 'Ngày tạo phiếu',
  warehouse_goods_issue_delivering_info: 'Hàng hoá vận chuyển',
  warehouse_quantity_import: 'SL xuất kho',
  warehouse_quantity_export: 'SL nhập kho',
  inventory_list: 'Danh sách hàng tồn kho',
  ticket_receive: 'Phiếu nhận hàng',
  receive_code: 'Mã phiếu chuyển hàng',
  warehouse_goods_issue_for_unit: 'Phiếu chuyển kho phòng khám',

  strategic_doctor: 'Định biên bác sĩ',
  all_month: 'Tất cả tháng',
  all_area: 'Tất cả khu vực',
  strategic_doctor_update: 'Cập nhật định biên bác sĩ',
  doctor_nurse_info: 'Thông tin bác sĩ & y tá',
  add_job_title_doctor_nurse: 'Thêm chức danh bác sĩ & y tá',
  add_job_title_doctor: 'Thêm chức danh bác sĩ',
  add_job_title_nurse: 'Thêm chức danh y tá',
  discount_campaign_management: 'Quản lý chiến dịch giảm giá',
  discount_campaign: 'Chiến dịch giảm giá',
  enter_campaign_name: 'Nhập tên chiến dịch',
  priority: 'Độ ưu tiên',
  discount_type: 'Loại giảm giá',
  discount_type_report_18: 'Loại giảm',
  apply_with_other_campaign: 'Áp dụng chung với KM khác',
  choose_apply_with_other_campaign: 'Chọn áp dụng chung với KM khác',
  apply_payment_discount: 'Áp dụng chiết khấu thanh toán',
  apply_discount_on_list_price: 'Áp dụng giảm giá trên giá niêm yết',
  apply_discount_after_promotion: 'Áp dụng giảm giá sau khuyến mãi',
  discount_form: 'Hình thức giảm giá',
  campaign_name: 'Tên chiến dịch',
  apply_area: 'Khu vực áp dụng',
  cancel_campaign: 'Huỷ chiến dịch',
  finish_campaign: 'Kết thúc chiến dịch',
  create_campaign: 'Tạo chiến dịch',
  campaign_info: 'Thông tin chiến dịch',
  apply_clinic: 'Phòng khám áp dụng',
  filter_apply_area: 'Lọc khu vực áp dụng',
  apply_service: 'Dịch vụ áp dụng',
  list_price: 'Giá niêm yết',
  discount_value: 'Giá trị giảm',
  campaign_type: 'Loại chiến dịch',
  type_of_offer: 'Loại ưu đãi',
  select_type_of_offer: 'Chọn loại ưu đãi',
  priority_number: 'Ưu tiên {{number}}',
  by_service: 'Theo từng dịch vụ',
  buy_A_discount_B: 'Mua A giảm B',
  voucher: 'Voucher',
  buy_bundle: 'Mua bundle',
  number_clinic: '{{number}} phòng khám',
  select_clinic_apply: 'Chọn phòng khám áp dụng',
  end_date_must_be_after_start_date: 'Ngày kết thúc phải sau ngày bắt đầu',
  select_priority: 'Chọn mức độ ưu tiên',
  select_campaign_type: 'Chọn loại chiến dịch',
  enter_service_name: 'Nhập tên dịch vụ',
  discount_percent: 'Giảm %',
  service_apply: 'Dịch vụ áp dụng',
  add_service_buy_discount: 'Thêm dịch vụ mua & giảm',
  enter_name: 'Nhập tên',
  buy_service: 'Dịch vụ mua',
  discount_service: 'Dịch vụ giảm',
  select_service: 'Chọn dịch vụ',
  select_discount_type: 'Chọn loại giảm giá',
  enter_discount_value: 'Nhập giá trị giảm giá',
  add_service_buy: 'Thêm dịch vụ mua',
  add_service_discount: 'Thêm dịch vụ giảm',
  update_service_discount: 'Cập nhật dịch vụ giảm giá',
  update_service_buy: 'Cập nhật dịch vụ mua',
  add_bundle: 'Thêm bundle',
  active_type: 'Loại kích hoạt',
  select_active_type: 'Chọn loại kích hoạt',
  auto: 'Tự động',
  create_campaign_success: 'Tạo chiến dịch thành công',
  update_campaign: 'Cập nhật chiến dịch',
  deactivate_campaign: 'Huỷ chiến dịch',
  are_you_want_deactivate_campaign: 'Bạn có muốn huỷ chiến dịch này không?',
  deactivate_campaign_success: 'Huỷ chiến dịch thành công',

  are_you_want_finish_campaign: 'Bạn có muốn kết thúc chiến dịch này không?',
  finish_campaign_success: 'Kết thúc chiến dịch thành công',

  campaign_cant_update:
    'Chiến dịch không thể cập nhật, vui lòng liên hệ quản trị viên',

  reactivate_campaign: 'Khôi phục chiến dịch',
  are_you_want_reactivate_campaign:
    'Bạn có muốn khôi phục chiến dịch này không?',
  reactivate_campaign_success: 'Khôi phục chiến dịch thành công',

  delete_voucher: 'Xoá voucher',
  are_you_want_delete_voucher: 'Bạn có muốn xoá voucher này không?',
  delete_voucher_success: 'Xoá voucher thành công',
  once_the_voucher_is_locked_it_cannot_be_recovered:
    'Một khi voucher đã bị xoá, nó không thể khôi phục',
  voucher_management: 'Quản lý voucher',
  voucher_campaign_management: 'Quản lý voucher chiến dịch "{{campaign}}"',
  create_voucher_success: 'Tạo voucher thành công',
  create_voucher: 'Tạo voucher',
  voucher_type: 'Loại voucher',
  method_issue_voucher: 'Hình thức voucher',
  method_issue: 'Hình thức',
  status_create: 'Trạng thái tạo',
  enter_name_voucher: 'Nhập tên voucher',
  handwriting_input: 'Tự nhập',
  applying: 'Đang áp dụng',
  stop_applying: 'Ngừng áp dụng',
  finished: 'Đã kết thúc',
  cash_discount: 'Giảm tiền mặt',
  fillable: 'Tự nhập',
  customer: 'Khách hàng',
  vip_customer: 'Khách hàng VIP',
  company_employee: 'Nhân viên công ty',
  ended: 'Đã kết thúc',
  waiting: 'Đang chờ',
  creating: 'Đang tạo',
  total_voucher: 'Tổng số voucher',
  unused: 'Chưa sử dụng',
  _used: 'Đã sử dụng',
  created_text: 'Đã tạo',
  waiting_apply: 'Chờ áp dụng',
  stop_apply: 'Ngừng áp dụng',
  update_voucher: 'Cập nhật voucher',
  voucher_info: 'Thông tin voucher',
  campaign_apply: 'Chiến dịch áp dụng',
  select_campaign: 'Chọn chiến dịch',
  select_method_issue_voucher: 'Chọn hình thức phát hành voucher',
  voucher_setting: 'Thiết lập voucher',
  number_of_characters: 'Số lượng ký tự',
  enter_number_of_characters: 'Nhập số lượng ký tự',
  custom_first_characters: 'Tuỳ chỉnh các ký tự đầu',
  discount_code: 'Mã khuyến mãi',
  enter_characters: 'Nhập ký tự',
  prefix_length_must_less_than_total_voucher:
    'Độ dài tiền tố phải nhỏ hơn tổng số voucher',
  discount_code_note:
    'Mã khuyến mãi bao gồm các ký tự (A-Z) viết hoa và số (0-9). Không chứa các ký tự đặc biệt (/.,:..).',
  target_user: 'Đổi tượng sử dụng',
  select_target_user: 'Chọn đối tượng sử dụng',
  customer_list: 'Danh sách khách hàng',
  discount_value_must_less_than_100: 'Giá trị giảm giá phải nhỏ hơn 100',
  number_of_uses: 'Số lần sử dụng',
  enter_number_of_uses: 'Nhập số lần sử dụng',
  select_voucher_type: 'Chọn loại voucher',
  active_campaign: 'Kích hoạt chiến dịch',
  invalid_field: 'Trường "{{field}}" không hợp lệ',
  detail_voucher: 'Chi tiết voucher',
  cash_voucher: 'Voucher giảm tiền mặt',
  system_auto_create_voucher: 'Hệ thống tự tạo voucher',
  fill_in_voucher_code: 'Tự nhập mã voucher',
  no_end_date: 'Không có ngày kết thúc',
  on_going: 'Đang diễn ra',
  up_coming: 'Sắp diễn ra',
  file_template: 'File mẫu',
  year_of_birth: 'Năm sinh',
  voucher_code: 'Mã voucher',
  number_of_use: 'Số lần sử dụng',
  applied: 'Đã áp dụng',
  not_applied: 'Chưa áp dụng',
  not_select_file: 'Chưa chọn file',
  full_name_customer_introduce: 'Họ Tên Khách Hàng Giới Thiệu',
  number_of_customers_introduce: 'Số Lượng Khách Hàng Giới Thiệu',
  value_of_customers_introduce: 'Giá Trị Khách Hàng Giới Thiệu',
  number_of_commission_received: 'Số Commission Đã Nhận',
  retry_generate_voucher: 'Thử lại tạo voucher',
  cancel_apply_voucher: 'Ngừng áp dụng voucher',
  restore_voucher: 'Khôi phục voucher',
  are_you_want_retry_generate_voucher: 'Bạn có muốn thử lại tạo voucher không?',
  are_you_want_cancel_apply_voucher: 'Bạn có muốn ngừng áp dụng voucher không?',
  are_you_want_restore_voucher: 'Bạn có muốn khôi phục voucher không?',
  detail_campaign: 'Chi tiết chiến dịch',
  update_campaign_success: 'Cập nhật chiến dịch thành công',
  not_yet_used: 'Chưa sử dụng',
  using: 'Đang sử dụng',
  total_voucher_and_customer_import_not_equal:
    'Tổng số voucher và số lượng khách hàng import (hợp lệ) chưa bằng nhau',
  total_voucher_and_referral_import_not_equal:
    'Tổng số voucher và số lượng đối tượng giới thiệu import (hợp lệ) chưa bằng nhau',
  you_can_only_create_up_to: 'Bạn chỉ có thể tạo tối đa {{total}} mã voucher',
  view_voucher_code_list: 'Xem danh sách mã voucher',
  export_excel_voucher_code_list: 'Xuất excel danh sách mã voucher',
  voucher_code_list: 'Danh sách mã voucher',
  promotion_management: 'Quản lý khuyến mãi',
  my_desk_en: 'Góc của tôi',
  promotion_ongoing: 'Khuyến mãi đang chạy',
  active_type_must_be_voucher: "Loại kích hoạt phải là 'Voucher'",
  report_15:
    'Report 015 - Báo cáo doanh thu và thực thu theo phòng khám của bác sĩ quản lý',
  report_16:
    'Report 016 - Báo cáo số khách hàng mới và tỉ lệ chốt của bác sĩ quản lý',
  estimated_percent: '% ước đoán',
  total_check_in: 'Tổng checkin',
  total_close: 'Tổng chốt',
  number_check_in: 'Số checkin',
  number_close: 'Số chốt',
  close_rate: '% tỉ lệ chốt',
  total_number_of_visits: 'Tổng số lượt',
  total_number_of_used: 'Lượt đã dùng',
  total_number_of_not_used: 'Lượt còn lại',
  total_voucher_template: 'Mẫu voucher',
  number_of_users: 'Số người sử dụng',
  start_date_must_be_after_campaign_start_date:
    'Ngày bắt đầu phải sau ngày bắt đầu chiến dịch ({{date}})',
  voucher_name: 'Tên voucher',
  enter_voucher_name: 'Nhập tên voucher',
  start_date_must_be_before_end_date: 'Ngày bắt đầu phải trước ngày kết thúc',
  clone_campaign: 'Nhân bản chiến dịch',
  report_introduce_program: 'Report chương trình giới thiệu referral',
  referer_report: 'Report chương trình giới thiệu referral',
  enter_customer_name: 'Nhập tên khách hàng',
  approve_day_off: 'Duyệt ngày nghỉ',
  fundiin: 'Fundiin',
  direct_payment: 'Trả thẳng',
  installment_direct_payment: 'Trả góp/Trả thẳng',
  view_detail_day_off: 'Xem chi tiết đơn nghỉ phép',
  day_off_detail: 'Chi tiết đơn nghỉ phép',
  reject_day_off: 'Từ chối nghỉ phép',
  duplicated_treatment: 'Trùng lặp Dịch vụ áp dụng',
  wrong_discount_type: 'Lỗi Loại giảm giá',
  wrong_discount_input: 'Lỗi Giá trị giảm',
  not_found_treatment: 'Không tìm thấy Dịch vụ',
  row: 'Dòng',
  error_vn_text: 'Lỗi',
  wrong_quantity_input: 'Lỗi Số lượng',
  not_found_buy_treatment: 'Không tìm thấy Dịch vụ mua',
  not_found_discount_treatment: 'Không tìm thấy Dịch vụ giảm',
  download_exist_data: 'Tải dữ liệu có sẵn',
  additional_service: 'Bổ sung dịch vụ',
  additional_service_success: 'Bổ sung dịch vụ thành công',
  additional_campaign: 'Bổ sung chiến dịch',
  additional_campaign_success: 'Bổ sung chiến dịch thành công',
  enter: 'Nhập',
  apply_rule: 'Quy tắc áp dụng',
  select_apply_rule: 'Chọn quy tắc áp dụng',
  new_customer: 'Khách hàng mới',
  designated_customer: 'Khách hàng chỉ định',
  all_customers: 'Tất cả khách hàng',
  report_18: 'Report 018 - Báo cáo tình hình khách hàng hưởng khuyến mãi',
  report_18_description: 'Báo cáo tình hình khách hàng hưởng khuyến mãi',
  enter_keyword_search: 'Nhập từ khóa tìm kiếm',
  apply_date: 'Thời gian áp dụng',
  discount_amount: 'Số lượng giảm giá',
  customer_name: 'Tên khách hàng',
  campaign: 'Chiến dịch',
  percent: '%',
  report_20: 'Report 020 - Thống kê lịch hẹn bác sĩ',
  report_20_description: 'Thống kê lịch hẹn bác sĩ',
  export_excel_success: 'Xuất excel thành công !',
  limit_discount_management: 'Quản lý giới hạn khuyến mãi',
  limit_discount: 'Giới hạn khuyến mãi',
  wrong_maximum_discount: 'Lỗi Giá trị giảm tối đa',
  total_bill: 'Tổng bill',
  add_value_total_bill: 'Thêm giá trị tổng bill',
  value_total_bill: 'Giá trị tổng bill',
  value_total_bill_apply: 'Giá trị tổng bill áp dụng',
  additional_bill_discount: 'Bổ sung mức giảm bill',
  additional_bill_discount_success: 'Bổ sung mức giảm bill thành công',
  additional: 'Bổ sung',
  add_group: 'Thêm nhóm',
  retail_price: 'Giá niêm yết',
  discounted_total: 'Giá sau giảm',
  campaign_apply_common: 'Chiến dịch áp dụng chung',
  select_campaign_apply_common: 'Chọn chiến dịch áp dụng chung',
  normal: 'Bình thường',
  patient_referral: 'Khách hàng giới thiệu',
  staff_referral: 'Nhân viên giới thiệu',
  add_campaign: 'Thêm chiến dịch',
  campaign_list: 'Danh sách chiến dịch',
  referral_target: 'Đối tượng giới thiệu',
  select_referral_target: 'Chọn tối tượng giới thiệu',
  number_of_uses_per_customer: 'Số lần sử dụng cho 1 khách hàng',
  enter_number_of_uses_per_customer: 'Nhập số lần sử dụng cho 1 khách hàng',
  employee_list: 'Danh sách nhân viên',
  daily_invoice_payment_for_treatment: 'Thanh toán cho DV',
  daily_invoice_payment_for_treatment_amount: 'Số tiền TT cho dịch vụ',
  daily_invoice_payment_for_receivable: 'Trả góp cho DV',
  daily_invoice_payment_for_receivable_amount: 'Số tiền trả góp cho DV',
  the_number_of_uses_per_customer_cannot_exceed_the_total_number_of_vouchers:
    'Số lần sử dụng cho 1 khách hàng không được lớn hơn tổng số voucher.',
  the_number_of_uses_per_customer_cannot_exceed_the_number_of_uses:
    'Số lần sử dụng cho 1 khách hàng không được lớn hơn số lần sử dụng.',
  referral_voucher_code_list: 'Danh sách mã referral voucher',
  view_referral_voucher_code_list: 'Xem danh sách mã referral voucher',
  end_date_must_be_after_old_end_date:
    'Ngày kết thúc phải sau ngày kết thúc cũ {{old}}',
  promotion_for_employees: 'Khuyến mãi dành cho nhân viên',
  referral_voucher_user_statistics: 'Thống kê người dùng referral voucher',
  number_of_voucher: 'Số lượng voucher',
  net_amount_or_paid: 'Đã thu tiền hoặc phát sinh giá trị',
  group_campaign: 'Nhóm chiến dịch',
  select_group_campaign: 'Chọn nhóm chiến dịch',
  patient_search: 'Tra cứu bệnh nhân',
  service_used: 'Dịch vụ khám',
  morning: 'Sáng',
  afternoon: 'Chiều',
  change_info: 'Thông tin thay đổi',
  approved_day_off: 'Ngày nghỉ đã duyệt',
  new_day_off: 'Ngày nghỉ mới',
  reject_reason: 'Lý do từ chối',
  approve_DO: 'Duyệt DO',
  reject_DO: 'Từ chối DO',
  approve_furlough: 'Duyệt nghỉ phép',
  reject_furlough: 'Từ chối nghỉ phép',
  approve_change_DO: 'Duyệt đổi DO',
  reject_change_DO: 'Từ chối đổi DO',
  update_successfully: 'Cập nhật thành công',
  furlough_time: 'Thời gian nghỉ',
  _day: 'ngày',
  linked_un_working_days: 'Đơn nghỉ phép liên quan',
  documents_images: 'Tài liệu & hình ảnh đính kèm',
  report_21: 'Report 021 - Khách hàng Referral',
  report_24: 'Report 024 - Báo cáo theo hình thức thanh toán',
  report_24_des: 'Báo cáo theo hình thức thanh toán',
  referral_source: 'Nguồn KH',
  referral_to_code: 'Mã người được giới thiệu ',
  referral_from_code: 'Mã người giới thiệu',
  referral_from_name: 'Tên người giới thiệu',
  payment_paid: 'Số tiền đã thanh toán',
  package: 'Gói dịch vụ',
  package_amount: 'Giá dịch vụ',
  change_avatar: 'Đổi ảnh',
  upload_image: 'Tải ảnh',
  avatar: 'Ảnh đại diện',
  avatar_description: 'Tải lên và thay đổi ảnh đại diện cá nhân.',
  personal_info: 'Thông tin cá nhân',
  personal_info_description_update:
    'Hiển thị họ & tên, SĐT, email, địa chỉ của nhân viên cho phép cập nhật, thay đổi.',
  personal_info_description:
    'Hiển thị họ & tên, SĐT, email, địa chỉ của nhân viên.',
  _skill: 'Kỹ năng ',
  skill_group: 'Nhóm kỹ năng ',
  skill_description_update: 'Cập nhật, bổ sung kỹ năng của nhân viên.',
  skill_description: 'Danh sách kỹ năng của nhân viên',
  certification: 'Bằng cấp',
  certification_description: 'Bổ sung, cập nhật bằng cấp của nhân viên.',
  avatar_upload_description:
    'Tải lên ảnh của bạn ( Định dạng PNGs, JPEGs. Kích thước tối đa 5 MB )',
  skill_list: 'Danh sách kỹ năng',
  certification_list: 'Danh sách bằng cấp',
  section_job_title: 'Chức danh chính & Chức danh kiêm nhiệm',
  job_title_description:
    'Hiển thị chức danh chính và chức dạnh kiêm nhiệm của nhân viên khi làm việc.',
  job_title_main_description:
    'Hiển thị chức danh chính của nhân viên khi làm việc.',
  job_title_secondary_description:
    'Hiển thị chức danh kiêm nhiệm của nhân viên khi làm việc.',
  office_manager: 'Quản lý hành chính',
  tech_manager: 'Quản lý chuyên môn',
  responsibility_manager: 'Quản lý trách nhiệm',
  office_manager_description:
    'Quản lý các vấn đề liên quan tới hành chính của nhân viên.',
  tech_manager_description: 'Quản lý về kỹ năng, chuyên môn của nhân viên.',
  responsibility_manager_description: 'Quản lý về trách nhiệm của nhân viên.',
  please_choose_require_fields: 'Vui lòng chọn tất cả trường bắt buộc',
  choose_manager: 'Chọn quản lý',
  direct_subordinate: 'Cấp dưới trực tiếp',
  indirect_subordinate: 'Cấp dưới gián tiếp',
  direct_subordinate_description:
    'Cấp dưới trực tiếp (về chuyên môn và trách nhiệm)',
  indirect_subordinate_description:
    'Cấp dưới gián tiếp (về chuyên môn và trách nhiệm)',
  direct_manager_description:
    'Quản lý trực tiếp về hành chính & chuyên môn & trách nhiệm',
  referral_code: 'Mã giới thiệu',
  employee_voucher: 'Voucher nhân viên',
  using_date: 'Thời hạn sử dụng',
  out_date: 'Hết hạn',
  no_expired_date: 'Vô thời hạn',
  referral_to_info: 'Thông tin người được giới thiệu (KH A)',
  referral_from_info: 'Thông tin người giới thiệu (KH B)',
  customer_A_service_used_info: 'Thông tin gói dịch vụ sử dụng của KH A',
  release_voucher_status: 'Theo dõi tình hình phát hành VOUCHER / Chi tiền',
  voucher_applied_date: 'Ngày apply mã VC',
  voucher_applied_package_name: 'Tên gói DV được apply VC',
  total_value_contract: 'Tổng giá trị hợp đồng',
  start_date_using_service: 'Ngày bắt đầu sử dụng DV',
  treatment_name_start_service: 'Tên thủ thuật ghi nhận bắt đầu DV',
  add_new_profile: 'Thêm hồ sơ mới',
  please_choose_import_file: 'Vui lòng chọn file import',
  choose_import_file: 'Chọn file import',
  enter_name_customer_code: 'Nhập tên/ mã KH để tìm kiếm',
  found: 'Đã tìm thấy',
  _result: 'kết quả',
  remain_debt: 'Công nợ còn lại của KH B',
  show_full_list: 'Hiển thị danh sách đầy đủ',
  payment_method_full: 'Trả Full',
  payment_method_pw: 'Trả góp Parkway',
  payment_method_fundiin: 'Trả góp Fundiin',
  payment_method_credit: 'Trả góp thẻ tín dụng',
  downloading: 'Đang tải',
  download_waiting: 'Đang chờ tải',
  download_errored: 'Tải xuống không thành công',
  download_warning: 'Lời nhắc',
  request_export_bill_management: 'Quản lý yêu cầu xuất hóa đơn',
  export_bill: 'Xuất hóa đơn',
  waiting_for_export: 'Chờ xuất',
  exported: 'Đã xuất',
  move_to_need_update: 'Chuyển sang Cần cập nhật',
  move_to_cancel: 'Chuyển sang Hủy',
  need_update: 'Cần cập nhật',
  enter_keyword: 'Nhập từ khoá tìm kiếm',
  content_reset: 'Reset',
  content_apply: 'Áp dụng',
  request_export_bill_date: 'Ngày yêu cầu',
  _patient_code: 'Mã số bệnh nhân',
  company_by_branch: 'Công ty theo chi nhánh',
  customer_info: 'THÔNG TIN KHÁCH HÀNG',
  export_bill_info: 'THÔNG TIN XUẤT HOÁ ĐƠN',
  service_use: 'Dịch vụ thực hiện',
  service_use_date: 'Ngày phát sinh dịch vụ',
  total_amount_receivable: 'Tổng số tiền phải thu',
  tax_code: 'MST',
  company_name_customer_name: 'Tên KH/công ty',
  email_receive_bill: 'Email nhận hoá đơn',
  service_request_export_bill: 'DV yêu cầu xuất HĐ',
  total_amount_export_bill: 'Tổng tiền xuất HĐ',
  status_export_bill: 'TRẠNG THÁI XUẤT HOÁ ĐƠN',
  exported_bill: 'Đã xuất HĐ',
  sent_mail: 'Đã gửi email',
  bill_information: 'Thông tin hoá đơn',
  enter_payment_code: 'Nhập mã thanh toán',
  enter_tax_code: 'Nhập mã số thuế',
  no_service_info: 'Chưa có thông tin dịch vụ',
  service_export_bill: 'Dịch vụ xuất hoá đơn',
  update_info_bill: 'Cập nhật thông tin hoá đơn',
  export_bill_placeholder: 'Tìm theo Tên KH/Mã thanh toán /MST',
  list_export_bill_request: 'Danh sách yêu cầu xuất hoá đơn',
  download_warning_description: 'Vui lòng bật thông báo để tải file trực tiếp',
  read_notification: 'Đã đọc',
  not_read_notification: 'Chưa đọc',
  report_23: 'Report 023 - Báo cáo đánh giá chất lượng dịch vụ qua ZNS',
  report_23_title: 'Báo cáo đánh giá chất lượng dịch vụ qua ZNS',
  date_survey: 'Ngày đánh giá',
  star_survey: 'Số sao đánh giá',
  appointment_star: 'Điểm đánh giá',
  appointment_code: 'Mã lịch hẹn',
  professional_competence: 'Năng lực chuyên môn',
  service_experience: 'Trải nghiệm dịch vụ',
  facilities: 'Cơ sở vật chất',
  satisfied: 'Hài lòng',
  dissatisfied: 'Không hài lòng',
  appointment_date_zns: 'Ngày đặt lịch',
  need_to_improve: 'Cần cải thiện',
  best_satisfied: 'Hài lòng nhất',
  need_to_improve_more: 'Cần cải thiện thêm',
  medical_record_and_information: 'Thông tin và bệnh án',
  info_patient: 'Thông tin cá nhân',
  survey_teeth_health: 'Khảo sát sức khoẻ răng miệng',
  medical_dental_history_survey: 'Khảo sát tiền sử bệnh lý Y khoa và Nha khoa',
  print: 'In',
  print_the_consent_for_the_information_privacy_policy:
    'In bản đồng ý chính sách bảo mật thông tin',

  find_campaign_by_voucher_code: 'Tìm chiến dịch theo mã voucher',
  find_campaign: 'Tìm chiến dịch',
  please_enter_voucher_code_into_search_box_below:
    'Vui lòng nhập mã voucher vào ô tìm kiếm phía dưới',
  enter_voucher_code: 'Nhập mã voucher',
  cancel_request_export_bill: 'Huỷ yêu cầu xuất hoá đơn',
  move_to_waiting_export: 'Chuyến sang chờ xuất',
  cancel_request_export_bill_description:
    'Bạn xác nhận huỷ yêu cầu xuất hoá đơn.\nBạn vẫn muốn tiếp tục?',
  report_24_content: {
    package_service: 'Gói dịch vụ',
    treatment_location_name: 'Cơ sở đăng kí dịch vụ',
    patient_default_location: 'Cơ sở điều trị mặc định',
    treatment_price: 'Giá niêm yết',
    marketing_discount_percent: 'Tỷ lệ KM (chính sách của MKT)',
    real_discount_percent: 'Tỷ lệ KM (thực tế được hưởng)',
    treatment_price_before_payment_discount: 'Giá trị gói trước chiết khấu',
    payment_discount_percent: 'Tỉ lệ chiết khấu',
    payment_discount_amount: 'Số tiền chiết khấu',
    treatment_final_price: 'Giá trị phải thu',
    installment_first_fee: 'Số tiền TT lần đầu trước khi điều trị',
    installment_period_fee: 'Số tiền trả góp hàng tháng',
    payment_for_orthodontics: 'Đã TT cho DV chỉnh nha',
    patients_debts_remain: 'Công nợ (tới hiện tại)',
    patients_debs_total_paid: 'Đã TT (toàn bộ giá trị)',
    patients_debts_remain_1: 'Số tiền còn lại (toàn bộ giá trị)',
    today_payment_orthodontics: 'Số tiền thu trong ngày cho DV niềng',
    begin_orthodontic_date: 'Ngày bắt đầu chỉnh nha',
    end_orthodontic_date: 'Ngày kết thúc chỉnh nha',
    orthodontic_month: 'Số tháng CN',
    apply: 'Áp dụng',
    reset: 'Reset',
    buy_orthodontic_date: 'Ngày phát sinh gói niềng',
    buy_orthodontic_date_1: 'Ngày phát sinh gói DV',
  },
  update_history: 'Lịch sử cập nhật',
  detail_degree: 'Chi tiết bằng cấp',
  process_promotion: 'Quá trình thăng tiến',
  appling: 'Đang áp dụng',
  stopped_used: 'Ngừng sử dụng',
  add_item_price: 'Thêm bảng giá',
  list_item_price: 'Danh sách bảng giá',
  copy_item_price: 'Sao chép bảng giá',
  create_draft_item_price: 'Tạo bảng nháp',
  create_item_price_name: 'Tạo tên bảng giá',
  create_item_price: 'Tạo bảng giá',
  price_item_name: 'Tên bảng giá',
  save: 'Lưu',
  item_price_detail: 'Chi tiết bảng giá',
  add_item_price_service: 'Thêm sản phẩm dịch vụ',
  all_item_price_service_title: 'Tất cả sản phẩm dịch vụ',
  all_item_price_service_description:
    'Hệ thống sẽ tự động thêm tất cả sản phẩm dịch vụ đang có tại Nha Khoa PARKWAY',
  select_custom_item_price_service_title: 'Chọn từng sản phẩm dịch vụ',
  select_custom_item_price_service_description:
    'Có thể chọn một hoặc nhiều sản phẩm dịch vụ đang có tại Nha Khoa PARKWAY',
  unit_column: 'Đơn vị',
  sku_column: 'Mã SKU',
  barcode_column: 'Barcode',
  delete_item_price_service: 'Xoá sản phẩm dịch vụ',
  delete_item_price_description1: 'Bạn xác nhận xóa sản phẩm dịch vụ',
  delete_item_price_description2:
    'Sản phẩm dịch vụ sau khi xóa sẽ không thể khôi phục lại',
  list_price_service: 'Danh sách sẩn phẩm dịch vụ',
  update_item_price: 'Cập nhật bảng giá',
  detail_item_price: 'Xem bảng giá sản phẩm/dịch vụ',
  title_delete_item_price: 'Xóa bảng giá',
  notify_delete_item_price_description_1: 'Bạn xác nhận xóa bảng giá',
  notify_delete_item_price_description_2:
    'Bảng giá sau khi xóa sẽ không thể khôi phục lại.',
  is_default_should_be_false: "Mặc định nên để là 'Không' khi đang là nháp",
  conflics_start_date_end_date_item_price_another:
    'Bị trùng thời gian với một bảng giá khác',
  already_an_item_is_default: 'Đã tồn tại bảng giá khác có trạng thái mặc định',
  information_item_prrice: 'Thông tin bảng giá',
}
